<template>
    <div id="div-main" class="container">
        <Loading v-if="isLoading" />

        <div class="row text-center">
            <h1 class="h3 mb-0 font-weight-normal">
                Cadastro de Pessoa Júridica/Fisica
            </h1>
        </div>

        <hr />

        <div class="row">
            <div class="col-1"></div>
            <div class="col-lg-11 col-md-12">
                <div v-if="!novoCadastro" class="row">
                    <h4>Pessoa de código: {{ idCadastro }}</h4>
                </div>
                <div class="row">
                    <div class="col-lg-5 col-md-12">
                        <label
                            for="numeroContrato"
                            class="form-label form-label-sm"
                            >Descrição *</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="descricao"
                            placeholder="Nome da Pessoa ou da Empresa"
                            v-model="descricao"
                            maxlength="200"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <label for="documento" class="form-label form-label-sm"
                            >Documento *</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="documento"
                            placeholder="Cpf ou Cnpj"
                            v-model="documento"
                            maxlength="18"
                            v-mask="['###.###.###-##', '##.###.###/####-##']"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <label for="email" class="form-label form-label-sm"
                            >E-mail</label
                        >
                        <input
                            type="text"
                            maxlength="50"
                            class="form-control form-control-sm"
                            id="email"
                            placeholder="E-mail da Pessoa"
                            v-model="email"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-12">
                        <label
                            for="numeroContrato"
                            class="form-label form-label-sm"
                            >Celular/Telefone</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nome"
                            placeholder="Celular ou Telefone da Pessoa"
                            v-model="telefone"
                            maxlength="20"
                            v-mask="['(##)#####-####']"
                        />
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="row">
            <div
                class="col-lg-1 col-md-12 d-grid d-md-block gap-2"
                v-if="propVoltar != null"
                style="margin-bottom: 8px"
            >
                <button
                    type="button"
                    class="ml-2 btn btn-secondario"
                    @click="voltar()"
                >
                    Voltar
                </button>
            </div>
            <div class="col-lg-11 col-md-12 ta-r d-grid d-md-block gap-2">
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="m-2 btn btn-secondario"
                    @click="AbrirHistorico()"
                >
                    Histórico
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="m-2 btn btn-secondario"
                    @click="LimparCadastro()"
                >
                    Novo Cadastro
                </button>
                <button
                    type="button"
                    v-if="novoCadastro"
                    class="m-2 btn btn-primeiro"
                    @click="CadastrarUsuario()"
                >
                    Cadastrar
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="m-2 btn btn-primeiro"
                    @click="AlterarCadastro()"
                >
                    Salvar
                </button>
            </div>
        </div>

        <modal-erp
            v-show="UiStatus.ModalHistorico"
            @close="FecharHistorico"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Histórico</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="FecharHistorico"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <table class="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>Usuário Alterou</th>
                                <th>Data Alteração</th>
                                <th>Descrição</th>
                                <th>Documento</th>
                                <th>E-mail</th>
                                <th>Celular/Telefone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(result, index) in historico"
                                :key="index"
                            >
                                <td>
                                    {{
                                        retornarDescricaoUsuario(
                                            result.idUsuario
                                        )
                                    }}
                                </td>
                                <td>{{ formataData(result.data) }}</td>
                                <td>{{ result.descricao }}</td>
                                <td>{{ result.cpfCnpj }}</td>
                                <td>{{ result.email }}</td>
                                <td>{{ formataTelefone(result.telefone) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </modal-erp>
    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import ModalErp from "@/components/ModalErp.vue";
import formatMixin from "@/uteis/formatMixin.js";
import { getHeaders } from "../../router/request";

export default {
    mixins: [formatMixin],
    name: "CadPessoa",
    components: {
        ModalErp,
        Loading,
    },
    data() {
        return {
            idCadastro: 0,
            descricao: "",
            email: "",
            telefone: "",
            documento: "",
            historico: [],
            retornaSucesso: false,
            UiStatus: {
                ModalHistorico: false,
            },
            isLoading: false,
        };
    },
    props: {
        propVoltar: {
            type: Function,
            default: null,
        },
    },
    computed: {
        novoCadastro() {
            return this.idCadastro == 0;
        },
    },
    mounted() {},
    methods: {
        voltar() {
            if (this.propVoltar != null) this.propVoltar();
        },
        validarCadastro() {
            if (this.descricao == "" || this.descricao == 0) {
                this.NotificacaoAlerta("Informe uma Descrição");
                return false;
            }

            if (this.documento == "" || this.documento == 0) {
                this.NotificacaoAlerta("Cpf/Cnpj");
                return false;
            }

            if (
                this.retornarApenasNumeros(this.documento).length != 11 &&
                this.retornarApenasNumeros(this.documento).length != 14 &&
                this.retornarApenasNumeros(this.documento).length != 0
            ) {
                this.NotificacaoAlerta("Informe o Cpf/Cnpj corretamente");
                return false;
            }

            if (
                this.retornarApenasNumeros(this.telefone).length != 10 &&
                this.retornarApenasNumeros(this.telefone).length != 11 &&
                this.retornarApenasNumeros(this.telefone).length != 0
            ) {
                this.NotificacaoAlerta(
                    "Informe o Celular/Telefone corretamente"
                );
                return false;
            }
            return true;
        },
        async CadastrarUsuario() {
            if (!this.validarCadastro()) return;

            this.isLoading = true;
            fetch(this.$store.state.url + "/pessoa/cadastrar.php", {
                method: "POST",
                body: JSON.stringify({
                    Email: this.email,
                    Descricao: this.descricao,
                    Telefone: this.telefone,
                    CpfCnpj: this.documento,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.erro && data.erro && data.mensagem.length > 0) {
                        this.$swal(data.mensagem);
                        return;
                    }
                    if (this.retornaSucesso) {
                        this.$emit("retornoSucesso", data);
                    } else {
                        this.AbrirPessoa(data);
                    }

                    this.NotificacaoSucesso("Pessoa cadastrada com sucesso.");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async AlterarCadastro() {
            if (!this.validarCadastro()) return;

            this.isLoading = true;
            fetch(this.$store.state.url + "/pessoa/alterar.php", {
                method: "POST",
                body: JSON.stringify({
                    Id: this.idCadastro,
                    Email: this.email,
                    Descricao: this.descricao,
                    Telefone: this.telefone,
                    CpfCnpj: this.documento,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.AbrirPessoa(data);

                    this.NotificacaoSucesso("Pessoa alterada com sucesso.");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        ObterContratoCompleto(id) {
            this.isLoading = true;
            fetch(this.$store.state.url + "/pessoa/obter.php?idPessoa=" + id, {
                method: "GET",
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.AbrirPessoa(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        AbrirPessoa(pessoa) {
            this.idCadastro = pessoa.id;
            this.descricao = pessoa.descricao;
            this.documento = pessoa.cpfCnpj;
            this.email = pessoa.email;
            this.telefone = pessoa.telefone;
            this.historico = pessoa.historico;
        },
        RetornarSucesso(bool) {
            this.LimparCadastro();
            this.retornaSucesso = bool;
        },
        SetarIdContrato(idPessoa) {
            this.idCadastro = idPessoa;
        },
        LimparCadastro() {
            this.idCadastro = 0;
            this.descricao = "";
            this.documento = "";
            this.email = "";
            this.telefone = "";
        },
        FecharHistorico() {
            this.UiStatus.ModalHistorico = false;
        },
        AbrirHistorico() {
            this.UiStatus.ModalHistorico = true;
        },
    },
};
</script>
<style scoped>
.row {
    margin-bottom: 10px;
}
.ml-2 {
    margin-left: 5px;
}
.ta-l {
    text-align: left;
}
.ta-r {
    text-align: right;
}
#div-main {
    background-color: white;
    min-height: 100vh;
}
</style>