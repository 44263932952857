<template>  
    <a class="navbar-brand" href="#">
        <img src="../assets/images/logoAmarela.png" alt="Logo" style="width:80px; heigth:50px; margin:-10px -10px -10px 20px" class="d-inline-block align-text-top"/>
    </a>
</template>
<script>


  export default {
    name: "NavbarHeader"
  }
</script>