<template>
    <div>
        <Loading v-if="isLoading" />
        <div class="d-none d-md-block d-lg-block d-xl-block">
            <div class="login-container">
                <div class="left-section">
                    <img
                        src="../assets/images/logoAmarela.png"
                        alt="Logo do Sistema"
                        class="img-fluid h-50"
                    />
                </div>
                <div class="right-section container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <h2 class="mb-4">
                                {{
                                    modoNovoCadastro == true
                                        ? "Cadastro"
                                        : "Login"
                                }}
                            </h2>
                        </div>
                        <div
                            class="col-12 mb-2"
                            v-if="modoNovoCadastro == true"
                        >
                            <label for="nomeEmpresa" class="form-label"
                                >Nome da empresa ou local de trabalho</label
                            >
                            <input
                                id="nomeEmpresa"
                                type="text"
                                class="form-control"
                                v-model="nomeEmpresa"
                            />
                        </div>
                        <div class="col-12 mb-2">
                            <label for="email" class="form-label">E-mail</label>
                            <input
                                type="text"
                                class="form-control"
                                v-model="email"
                                required
                            />
                        </div>
                        <div
                            class="col-12 mb-2"
                            v-if="modoNovoCadastro == false"
                        >
                            <label for="password" class="form-label"
                                >Senha</label
                            >
                            <input
                                type="password"
                                class="form-control"
                                v-model="password"
                                required
                                v-on:keyup.enter="handleEnterLogin"
                            />
                        </div>
                        <div
                            class="col-12 mb-4"
                            v-show="modoNovoCadastro == true"
                        >
                            <Senha
                                :novaSenha="novaSenha"
                                :confirmacaoNovaSenha="confirmacaoNovaSenha"
                                classeCampos=""
                                ref="senha1"
                            />
                        </div>
                        <div
                            class="col-12 d-inline-flex justify-content-between w-100"
                        >
                            <button
                                type="submit"
                                class="btn btn-primeiro"
                                @click="entrar('senha1')"
                            >
                                {{
                                    modoNovoCadastro == true
                                        ? "Cadastrar"
                                        : "Entrar"
                                }}
                            </button>
                            <div v-if="modoNovoCadastro == false">
                                <button
                                    type="submit"
                                    class="btn btn-segundario"
                                    @click="recuperarSenha()"
                                >
                                    Esqueci a Senha
                                </button>
                                <button
                                    type="submit"
                                    class="btn btn-segundario"
                                    @click="modoNovoCadastro = true"
                                >
                                    Cadastrar-se
                                </button>
                            </div>
                            <div v-else>
                                <button
                                    type="submit"
                                    class="btn btn-segundario"
                                    @click="modoNovoCadastro = false"
                                >
                                    Entrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="login-container-sm container-fluid d-block d-md-none d-lg-none d-xl-none"
        >
            <div class="row rowLogo mb-3">
                <div class="col-3"></div>
                <div class="col-6">
                    <img
                        src="../assets/images/logoAmarela.png"
                        alt="Logo do Sistema"
                        class="img-fluid"
                    />
                </div>
                <div class="col-3"></div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h2 class="mb-4">
                        {{ modoNovoCadastro == true ? "Cadastro" : "Login" }}
                    </h2>
                </div>
                <div class="col-12 mb-3" v-if="modoNovoCadastro == true">
                    <label for="nomeEmpresa" class="form-label"
                        >Nome da empresa ou local de trabalho</label
                    >
                    <input
                        id="nomeEmpresa"
                        type="text"
                        class="form-control"
                        v-model="nomeEmpresa"
                    />
                </div>
                <div class="col-12 mb-3">
                    <label for="email" class="form-label">E-mail</label>
                    <input
                        type="text"
                        class="form-control"
                        v-model="email"
                        required
                    />
                </div>
                <div class="col-12 mb-3" v-if="modoNovoCadastro == false">
                    <label for="password" class="form-label">Senha</label>
                    <input
                        type="password"
                        class="form-control"
                        v-model="password"
                        required
                    />
                </div>
                <div class="col-12 mb-3" v-else>
                    <Senha
                        :novaSenha="novaSenha"
                        :confirmacaoNovaSenha="confirmacaoNovaSenha"
                        ref="senha2"
                    />
                </div>
                <div class="row mb-3">
                    <div class="col-6">
                        <button
                            class="btn btn-primeiro"
                            @click="entrar('senha2')"
                        >
                            {{
                                modoNovoCadastro == true
                                    ? "Cadastrar"
                                    : "Entrar"
                            }}
                        </button>
                    </div>
                    <div class="col-6 ta-r" v-if="modoNovoCadastro == false">
                        <button
                            type="submit"
                            class="btn btn-segundario btn-sm"
                            @click="recuperarSenha()"
                        >
                            Esqueci a Senha
                        </button>
                        <button
                            type="submit"
                            class="btn btn-segundario btn-sm"
                            @click="modoNovoCadastro = true"
                        >
                            Cadastrar-se
                        </button>
                    </div>
                    <div class="col-6 ta-r" v-else>
                        <button
                            type="submit"
                            class="btn btn-segundario btn-sm"
                            @click="modoNovoCadastro = false"
                        >
                            Entrar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <modal-erp
            v-show="modalEmpresas"
            @close="fecharModal"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative w-50'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Selecione a empresa</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="fecharModal"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <div class="col-lg-12 col-sm-6 mb-2">
                        <label class="form-label">Empresas</label>
                        <select
                            class="form-control"
                            v-model="empresaSelecionada"
                        >
                            <option
                                v-for="v in empresas"
                                :key="v.descricao"
                                :value="v.id"
                            >
                                {{ v.descricao }}
                            </option>
                        </select>
                    </div>

                    <div class="row">
                        <div class="col-12 ta-r d-grid d-md-block gap-2">
                            <button
                                class="btn btn-primeiro"
                                @click="entrarEmpresa()"
                            >
                                Entrar
                            </button>
                        </div>
                    </div>
                </div>
            </template>
        </modal-erp>
    </div>
</template>
<script>
import Loading from "@/components/Loading.vue";
import ModalErp from "@/components/ModalErp.vue";
import Senha from "@/components/Senha.vue";
import { isSignedIn } from "./../router/auth";

export default {
    name: "LoginComponent",
    components: {
        ModalErp,
        Loading,
        Senha,
    },
    data() {
        return {
            email: "",
            password: "",
            modoNovoCadastro: false,
            empresas: [],
            empresaSelecionada: 0,
            modalEmpresas: false,
            isLoading: false,
            novaSenha: "",
            confirmacaoNovaSenha: "",
            nomeEmpresa: "",
        };
    },
    mounted() {
        console.log("teste");
        console.log(process.env.VUE_APP_NOME_APLICACAO);
        console.log(this.$store.state.url);
        console.log(this.$store.state.versao);
        if (isSignedIn()) this.$router.push("/Inicio");
    },
    methods: {
        entrar(refSenha) {
            if (this.email == null || this.email == "") {
                this.$swal("Preencha o campo e-mail");
                return;
            }

            if (this.modoNovoCadastro == false) {
                if (this.password == null || this.password == "") {
                    this.$swal("Preencha o campo senha");
                    return;
                }
                this.logar();
            } else {
                if (this.nomeEmpresa == null || this.nomeEmpresa == "") {
                    this.$swal(
                        "Preencha o campo Nome da empresa ou local de trabalho"
                    );
                    return;
                }
                if (!this.$refs[refSenha].ValidarSenha()) {
                    this.$swal("Preencha a senha corretamente");
                    return;
                }
                console.log(this.$refs[refSenha].novaSenha);
                this.cadastrarNovoUsuario(
                    this.nomeEmpresa,
                    this.email,
                    this.$refs[refSenha].novaSenha
                );
            }

            if (1 == 1) return;

            this.isLoading = true;
        },
        cadastrarNovoUsuario(nomeEmpresa, email, senha) {
            debugger;
            this.isLoading = true;
            fetch(
                this.$store.state.url + "/Usuario/cadastrarUsuarioEmpresa.php",
                {
                    method: "POST",
                    body: JSON.stringify({
                        Email: email,
                        Senha: senha,
                        NomeEmpresa: nomeEmpresa,
                    }),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    if (!data) {
                        this.$swal("Erro imprevisto ao cadastrar");
                        return;
                    }
                    if (data.sucesso == false) {
                        if (data.texto && data.texto != "")
                            this.$swal(data.texto);
                        else {
                            this.$swal("Erro imprevisto ao cadastrar");
                        }
                        return;
                    }
                    if (data.texto && data.texto != "") this.$swal(data.texto);

                    if (data.token != "") {
                        window.localStorage.setItem("token", data.token);
                        this.$store.dispatch("atualizarCredencial", data.token);
                        this.$store.dispatch("atualizarUsuario", data.usuario);
                        if (isSignedIn()) this.$router.push("/Inicio");
                        else {
                            this.$swal("Erro ao cadastrar usuário");
                        }
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        logar() {
            fetch(this.$store.state.url + "/index.php", {
                method: "POST",
                body: JSON.stringify({
                    Email: this.email,
                    Senha: this.password,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error && data.error) {
                        this.$swal(data.error);
                        return;
                    }
                    var self = this;
                    if (
                        data.usuarioEmpresas &&
                        data.usuarioEmpresas.length > 1
                    ) {
                        this.isLoading = false;
                        self.empresas = [];
                        data.usuarioEmpresas.forEach((emp) => {
                            self.empresas.push({
                                id: emp.empresa,
                                descricao: emp.descricaoEmpresa,
                            });
                        });
                        self.modalEmpresas = true;
                    } else if (data.token != "") {
                        window.localStorage.setItem("token", data.token);
                        this.$store.dispatch("atualizarCredencial", data.token);
                        this.$store.dispatch("atualizarUsuario", data.usuario);
                        if (isSignedIn()) this.$router.push("/Inicio");
                        else {
                            this.$swal("usuário inválido");
                        }
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        entrarEmpresa() {
            this.isLoading = true;
            fetch(this.$store.state.url + "/index.php", {
                method: "POST",
                body: JSON.stringify({
                    Email: this.email,
                    Senha: this.password,
                    Empresa: this.empresaSelecionada,
                }),
            })
                .then((res) => res.json())
                .then((data) => {
                    var self = this;
                    self.empresas = [];
                    if (
                        data.usuarioEmpresas &&
                        data.usuarioEmpresas.length > 1
                    ) {
                        this.isLoading = false;
                        data.usuarioEmpresas.forEach((emp) => {
                            self.empresas.push({
                                id: emp.empresa,
                                descricao: emp.descricaoEmpresa,
                            });
                        });
                        self.modalEmpresas = true;
                    } else if (data.token != "") {
                        window.localStorage.setItem("token", data.token);
                        this.$store.dispatch("atualizarCredencial", data.token);
                        this.$store.dispatch("atualizarUsuario", data.usuario);
                        if (isSignedIn()) this.$router.push("/Inicio");
                        else {
                            this.$swal("usuário inválido");
                        }
                    }
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        handleEnterLogin() {
            this.entrar();
        },
        fecharModal() {
            this.modalEmpresas = false;
        },
        recuperarSenha() {
            if (this.email == "") {
                this.$swal("Favor preencha o campo e-mail");
                return;
            }

            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Usuario/esqueciSenha.php?email=" +
                    this.email,
                {
                    method: "GET",
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.$swal(
                        "Foi enviado um e-mail para sua conta para recuperar o acesso."
                    );
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style scoped>
body {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to right, #222831, #37414f); /* Degrade */
}
.login-container {
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    min-height: 96vh;
}
.login-container-sm {
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

    min-height: 96vh;
}
.left-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #222831, #37414f); /* Degrade */
}
.right-section {
    flex: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rowLogo {
    background: linear-gradient(to right, #222831, #37414f); /* Degrade */
}
.ta-r {
    text-align: right;
}
</style>