export default {
    computed: {
        permissaoExcluirEncerrar(){
            try{
                var idTipoUsuario = this.$store.state.idTipoUsuario;
                if(idTipoUsuario == 2)
                    return true;
                return false;
            }catch{
                return false;
            }
        },
        usuarioAdm(){
            try{
                var idTipoUsuario = this.$store.state.idTipoUsuario;
                if(idTipoUsuario == 2)
                    return true;
                return false;
            }catch{
                return false;
            }
        }
    },
    methods: {
        retornarApenasNumeros(entrada){
            return this.numeros = entrada.replace(/\D/g, '');
        },
        retornarDescricaoUsuario(idUsuario){
            try{
                var usuario = this.$store.state.listaUsuarios;
                usuario = usuario.find(u => u.id.toString() == idUsuario.toString());
                return "("+idUsuario+") - " + usuario.nome;
            }catch{
                return '';
            }
        },
        retornarCategoria(idCategoria){
            try{
                var categorias = this.$store.state.listaCategorias;
                categorias = categorias.find(u => u.id == idCategoria);
                return categorias.descricao;
            }catch{
                return '';
            }
        },
        retornarNomeUsuario(idUsuario){
            try{
                var usuario = this.$store.state.listaUsuarios;
                usuario = usuario.find(u => u.id == idUsuario);
                return usuario.nome;
            }catch{
                return '';
            }
        },
        formataBool(valor){
            if(valor == true)
                return "Sim";
            else
                return "Não";
        },
        formataBooleano(valor){
            return this.formataBool(valor);
        },
        validarDataFormato(data) {
            // Verificar se a data foi informada
            if (!data)
                return false;
    
            // Verificar o padrão de formato dd/mm/yyyy usando expressão regular
            const formatoValido = /^\d{2}\/\d{2}\/\d{4}$/;
            if (!formatoValido.test(data))
                return false;
    
            // Verificar se a data é válida usando a classe Date do JavaScript
            const partesData = data.split('/');
            const dia = parseInt(partesData[0], 10);
            const mes = parseInt(partesData[1], 10) - 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
            const ano = parseInt(partesData[2], 10);
            const dataObjeto = new Date(ano, mes, dia);
    
            // Comparar se os valores de dia, mês e ano são iguais aos valores informados
            if (
                dataObjeto.getDate() !== dia ||
                dataObjeto.getMonth() !== mes ||
                dataObjeto.getFullYear() !== ano
            )
                return false;
    
            return true;
        },
        dataUmMaiorQueDataDois(data1, data2){
            // Verificar se a data é válida usando a classe Date do JavaScript
            const partesData = data1.split('/');
            const dia = parseInt(partesData[0], 10);
            const mes = parseInt(partesData[1], 10) - 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
            const ano = parseInt(partesData[2], 10);
            const dataObjeto = new Date(ano, mes, dia);

            
            // Verificar se a data é válida usando a classe Date do JavaScript
            const partesData2 = data2.split('/');
            const dia2 = parseInt(partesData2[0], 10);
            const mes2 = parseInt(partesData2[1], 10) - 1; // Os meses em JavaScript são baseados em zero (janeiro = 0)
            const ano2 = parseInt(partesData2[2], 10);
            const dataObjeto2 = new Date(ano2, mes2, dia2);

            return dataObjeto > dataObjeto2;
        },
        retornarTipoContrato(idTipoContrato){
            try{
                var tipoContratos = this.$store.state.listaTipoContrato;
                tipoContratos = tipoContratos.find(u => u.id == idTipoContrato);
                return tipoContratos.descricao;
            }catch{
                return '';
            }
        },
        formataData(value){
            if(!value)
                return '';
            return new Date(value).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' });
        },
        formataDataHora(value){
            if(!value)
                return '';
            return new Date(value).toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour: ('2-digit'), minute: ('2-digit') });
        },
        formatarEndereco(contrato){
          let cep = ""
          if(contrato.cep != null && contrato.cep.length > 0)
            cep = "CEP: " + contrato.cep + "    ";
          let endereco = ""
          if(contrato.estado)
            endereco = ", " + contrato.estado;
          if(contrato.cidade)
            endereco += ", " + contrato.cidade;
          if(contrato.bairro)
            endereco += ", " + contrato.bairro;
          if(contrato.logradouro)
            endereco += ", " + contrato.logradouro;
          if(contrato.numero)
            endereco += ", " + contrato.numero;
          if(contrato.complemento)
            endereco += ", " + contrato.complemento;
    
          if(endereco != "" && endereco.length > 0)
            endereco = "Endereço:" + endereco.substring(1, endereco.length-1);
          if(cep != "")
            return cep + endereco;
          else
            return endereco;
    
        },
        formataValor(number, options = {}){
              const { moneySign = true } = options;
    
            if(Number.isNaN(number) || !number) return "need a number as the first parameter";
    
            if(typeof number === "string") { // n1
                number = Number(number);
            }
    
            let res;
    
            const config = moneySign ? {style: 'currency', currency: 'BRL'} : {minimumFractionDigits: 2};
    
            moneySign
            ? res = number.toLocaleString('pt-BR', config)
            : res = number.toLocaleString('pt-BR', config)
    
            const needComma = number => number <= 1000;
            if(needComma(number))
                res = res.toString().replace(".", ",");
    
             return res;
        },
        NotificacaoSucesso(texto){
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
            Toast.fire({
                icon: 'success',
                title: texto
            });
        },
        NotificacaoAlerta(texto){    
            
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            });
            Toast.fire({
                icon: 'warning',
                title: texto
            });
        },
        formataTelefone(value){
            value = value.toString();
            if(!value)
                return '';
            if(value.length.toString() == 11)
                return '(' + value.substring(0, 2) + ') ' + value.substring(2,7) + '-' + value.substring(7,11);
            else
                return value;
        },
        mascaraCpfCnpj(texto){
            if(!texto)
                return;
    
            if(texto.length == 14)
                return texto.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            else
                return texto.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },
        mascaraTelefone(texto){
            
            if(texto.length == 11)
                return texto.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
                
            if(texto.length == 10)
                return texto.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
            else
                return texto;
        },
        descricaoContratante(idTipoContrato){
            if(idTipoContrato.toString() == "1")
                return "Vendedor";
            if(idTipoContrato.toString() == "2")
                return "Locador";
            if(idTipoContrato.toString() == "4")
                return "Primeiro Parceiro";
            if(idTipoContrato.toString() == "6")
                return "Locador";
            if(idTipoContrato.toString() == "8")
                return "Fornecedor";
            if(idTipoContrato.toString() == "9")
                return "Arrendador";
            if(idTipoContrato.toString() == "10")
                return "Primeiro sócio";
            if(idTipoContrato.toString() == "12")
                return "Franqueador";
            if(idTipoContrato.toString() == "13")
                return "Seguradora";
            if(idTipoContrato.toString() == "17")
                return "Primeiro Contratante";
            if(idTipoContrato.toString() == "18")
                return "Comodante";
            if(idTipoContrato.toString() == "20" || idTipoContrato.toString() == "39")
                return "Cedente";
            if(idTipoContrato.toString() == "21")
                return "Doador";
            if(idTipoContrato.toString() == "25" || idTipoContrato.toString() == "40")
                return "Represetante";
            if(idTipoContrato.toString() == "7" || idTipoContrato.toString() == "27" || idTipoContrato.toString() == "45" || idTipoContrato.toString() == "36")
                return "Licenciante";
            if(idTipoContrato.toString() == "34")
                return "Mutuante";

                return "Contratante";
        }, 
        descricaoContratado(idTipoContrato){
            if(idTipoContrato.toString() == "1")
                return "Comprador";
            if(idTipoContrato.toString() == "2")
                return "Locatário";
            if(idTipoContrato.toString() == "4")
                return "Segundo Parceiro";
            if(idTipoContrato.toString() == "6")
                return "Empregador";
            if(idTipoContrato.toString() == "8")
                return "Cliente ou consumidor";
            if(idTipoContrato.toString() == "9")
                return "Arrendatário";
            if(idTipoContrato.toString() == "10")
                return "Segundo sócio";
            if(idTipoContrato.toString() == "12")
                return "Franqueado";
            if(idTipoContrato.toString() == "13")
                return "Segurado";
            if(idTipoContrato.toString() == "17")
                return "Segundo Contratante";
            if(idTipoContrato.toString() == "18")
                return "Comodatário";
            if(idTipoContrato.toString() == "20" || idTipoContrato.toString() == "39")
                return "Cessionário";
            if(idTipoContrato.toString() == "21")
                return "Beneficiário";
            if(idTipoContrato.toString() == "25" || idTipoContrato.toString() == "40")
                return "Representado";
            if(idTipoContrato.toString() == "7" || idTipoContrato.toString() == "27" || idTipoContrato.toString() == "45" || idTipoContrato.toString() == "36")
                return "Licenciado";
            if(idTipoContrato.toString() == "34")
                return "Mutuário";

            return "Contratado";
        },
    }
};