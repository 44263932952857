
const baseURL = 'https://localhost:44383/api/';
/*
function getHeaders () {
  const token = localStorage.getItem('token');
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    ...token && {
      'Authorization': `Bearer ${token}`
    }
  };
}*/
function getHeaders () {
  const token = localStorage.getItem('token');
  return {
    'access-control-allow-origin': '*',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
    'Access-Control-Allow-Methods': '*',
    ...token && {
      'Authorization': `${token}`
    }
  };
}

function getHeadersImagem () {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'multipart/form-data',
    ...token && {
      'Authorization': `Bearer ${token}`
    }
  };
}

async function request (method, url, body) {
  const options = {
    method,
    headers: getHeaders(),
    ...(method !== 'GET') && {
      body: JSON.stringify(body)
    }
  };
  const response = await fetch(baseURL + url, options);
  return await response.json();
}

export { request as default, request, getHeaders, getHeadersImagem }