import decode from 'jwt-decode';
import request from './request';

export async function signIn (email, password) {
  const { retorno } = await request('POST', '/Login/Logar', {
    "Email": email,
    "Senha": password
  });
  window.localStorage.set('token', retorno.token);
}

export function signOut () {
  window.localStorage.removeItem('token');
}

export function isSignedIn () {
  const token = window.localStorage.getItem('token');
  
  if (!token)     // Se não existe o token no LocalStorage
    return false; // significa que o usuário não está assinado.

  try {
    const { exp: expiration } = decode(token);
    const isExpired = !!expiration && Date.now() > expiration * 1000;

    if (isExpired)  // Se o token tem uma data de expiração e
      return false; // essa data é menor que a atual o usuário
                    // não está mais assinado.
    return true;
  } catch (_) {   // O "jwt-decode" lança erros pra tokens inválidos.
    return false; // Com um token inválido o usuário não está assinado.
  }
}

export function tokenExpirado (token) {
  if (!token || token == '')     // Se não existe o token no LocalStorage
    return false; // significa que o usuário não está assinado.

  try {
    const { exp: expiration } = decode(token);
    const isExpired = !!expiration && Date.now() > expiration * 1000;

    if (isExpired)  // Se o token tem uma data de expiração e
      return false; // essa data é menor que a atual o usuário
                    // não está mais assinado.
    return true;
  } catch (_) {   // O "jwt-decode" lança erros pra tokens inválidos.
    return false; // Com um token inválido o usuário não está assinado.
  }
}