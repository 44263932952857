import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import VueTheMask from 'vue-the-mask'
import Notifications from '@kyvg/vue3-notification'
import FontAwesomeIcon from "./uteis/fontawesome-icons.ts";
import money from 'v-money3'
import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(VueSweetalert2).use(Notifications).use(VueTheMask).use(store).use(router).use(money).mount('#app')
