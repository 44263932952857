<template>
    <div>
        <div
            class="row"
            style="padding: 5px"
            v-show="historicoContrato.length > 0"
        >
            <ul class="list-group list-group-striped">
                <h5>Código do Contrato {{ idContrato }}</h5>
                <li
                    class="list-group-item list-group-item"
                    v-for="contrato in historicoContrato"
                    :key="contrato.idContrato"
                >
                    <small>
                        <div class="row">
                            <div class="col-12">
                                <b>{{ contrato.atual ? "Atual - " : "" }} </b>
                                <b
                                    >Usuário
                                    {{
                                        retornarDescricaoUsuario(
                                            contrato.idUsuario
                                        )
                                    }}</b
                                >
                                inseriu as informações deste card na data
                                {{ formataDataHora(contrato.dataAlterado) }}.
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-12">
                                <b>Objeto: </b>{{ contrato.deObjeto }}
                            </div>
                            <div class="col-lg-4 col-12">
                                <b>Categoria: </b
                                >{{ retornarCategoria(contrato.idCategoria) }}
                            </div>
                            <div class="col-lg-4 col-12">
                                <b>Tipo contrato: </b
                                >{{
                                    retornarTipoContrato(
                                        contrato.idTipoContrato
                                    )
                                }}
                            </div>
                            <div class="col-lg-4 col-sm-6">
                                <b>Valor contrato: </b
                                >{{ formataValor(contrato.vlContrato) }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-sm-12">
                                <b>Data Assinatura: </b>
                                {{ formataData(contrato.dtAssinatura) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>Termo inicial:</b>
                                {{ formataData(contrato.vigenciaInicial) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>Termo final:</b>
                                {{ formataData(contrato.vigenciaFinal) }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-6">
                                <b>Assinado</b>:
                                {{ formataBooleano(contrato.assinatura) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>LGPD</b>
                                {{ formataBooleano(contrato.flLgpd) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>NDA</b> {{ formataBooleano(contrato.flNda) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>Compliance</b>
                                {{ formataBooleano(contrato.flComplice) }}
                            </div>
                            <div class="col-lg-4 col-6">
                                <b>E-mail válido</b>
                                {{ formataBooleano(contrato.flEmailValido) }}
                            </div>
                            <div class="col-lg-4 col-12">
                                <b>Renovação automática</b>
                                {{
                                    formataBooleano(
                                        contrato.renovacaoAutomatico
                                    )
                                }}
                            </div>
                        </div>
                        <div class="row" v-if="contrato.idTipoContrato == 2">
                            <div class="col-12">
                                <b>Endereço</b> {{ formatarEndereco(contrato) }}
                            </div>
                        </div>
                    </small>
                    <br />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContratoHistoricoCard",
    props: {
        historicoContrato: {
            type: Object,
            required: false,
            default: () => ({}),
        },
    },
    computed: {
        idContrato() {
            return this.historicoContrato[0]?.idContrato;
        },
    },
    methods: {
        formataData(value) {
            if (!value) return "";
            return new Date(value).toLocaleDateString("pt-BR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            });
        },
        formataDataHora(value) {
            if (!value) return "";
            return new Date(value).toLocaleDateString("pt-BR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            });
        },
        formataBooleano(valor) {
            if (valor) return "Sim";
            else return "Não";
        },
        mascaraCpfCnpj(texto) {
            if (!texto) return;

            if (texto.length == 14)
                return texto.replace(
                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                    "$1.$2.$3/$4-$5"
                );
            else
                return texto.replace(
                    /^(\d{3})(\d{3})(\d{3})(\d{2})/,
                    "$1.$2.$3-$4"
                );
        },
        marcaraTelefone(texto) {
            return texto.replace(/^(\d{2})(\d{9})/, "($1) $2");
        },
        retornarDescricaoUsuario(idUsuario) {
            try {
                var usuario = this.$store.state.listaUsuarios;
                usuario = usuario.find(
                    (u) => u.id.toString() == idUsuario.toString()
                );
                return "(" + idUsuario + ") - " + usuario.nome;
            } catch {
                return "";
            }
        },
        retornarCategoria(idCategoria) {
            try {
                var categorias = this.$store.state.listaCategorias;
                categorias = categorias.find((u) => u.id == idCategoria);
                return categorias.descricao;
            } catch {
                return "";
            }
        },
        retornarTipoContrato(idTipoContrato) {
            try {
                var tipoContratos = this.$store.state.listaTipoContrato;
                tipoContratos = tipoContratos.find(
                    (u) => u.id == idTipoContrato
                );
                return tipoContratos.descricao;
            } catch {
                return "";
            }
        },
        formatarEndereco(contrato) {
            let cep = "";
            if (contrato.cep != null && contrato.cep.length > 0)
                cep = "CEP: " + contrato.cep + "    ";
            let endereco = "";
            if (contrato.estado) endereco = ", " + contrato.estado;
            if (contrato.cidade) endereco += ", " + contrato.cidade;
            if (contrato.bairro) endereco += ", " + contrato.bairro;
            if (contrato.logradouro) endereco += ", " + contrato.logradouro;
            if (contrato.numero) endereco += ", " + contrato.numero;
            if (contrato.complemento) endereco += ", " + contrato.complemento;

            if (endereco != "" && endereco.length > 0)
                endereco =
                    "Endereço:" + endereco.substring(1, endereco.length - 1);
            if (cep != "") return cep + endereco;
            else return endereco;
        },
        formataValor(number, options = {}) {
            const { moneySign = true } = options;

            if (Number.isNaN(number) || !number)
                return "need a number as the first parameter";

            if (typeof number === "string") {
                // n1
                number = Number(number);
            }

            let res;

            const config = moneySign
                ? { style: "currency", currency: "BRL" }
                : { minimumFractionDigits: 2 };

            moneySign
                ? (res = number.toLocaleString("pt-BR", config))
                : (res = number.toLocaleString("pt-BR", config));

            const needComma = (number) => number <= 1000;
            if (needComma(number)) res = res.toString().replace(".", ",");

            return res;
        },
    },
};
</script>
<style scoped>
.ml-2 {
    margin-left: 2px;
}
.ta-r {
    text-align: right;
}
</style>