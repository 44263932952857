import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const getDefaultState = () => {
  return {
    nomeUsuario: 'Teste',
    versao: '2.3',
    idEmpresa: 1,
    idTipoUsuario: 0,
    listaUsuarios: [],
    listaCategorias: [],
    listaTipoContrato: [],
    token: '',
    url: process.env.VUE_APP_URL_BACKEND
    //url: 'http://localhost/gere'
    //url: 'http://localhost:3000'
  }
}

export default createStore({
  state: getDefaultState(),
  getters: {
    usuarios: function (state) {
      return state.listaUsuarios;
    },
    categorias: function (state) {
      return state.listaCategorias;
    },
    tipoContrato: function (state) {
      return state.listaTipoContrato;
    },
    token: function (state) {
      return state.token;
    },
    tipoUsuario: function (state) {
      return state.idTipoUsuario;
    },
    exibeMenu: function (state) {
      return state.token != '';
    }
  },
  mutations: {
    AtualizarUsuarios(state, payload) {
      state.listaUsuarios = payload;
    },

    AtualizarCategorias(state, payload) {
      state.listaCategorias = payload;
    },

    AtualizarTipoContrato(state, payload) {
      state.listaTipoContrato = payload;
    },

    AtualizarCredencial(state, payload) {
      state.token = payload;
    },
    AtualizarUsuario(state, payload) {
      state.idTipoUsuario = payload.idTipoUsuario;
      state.idEmpresa = payload.empresa;
    },
    ResetState(state) {
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    atualizarUsuarios(context, payload) {
      context.commit('AtualizarUsuarios', payload)
    },

    atualizarCategorias(context, payload) {
      context.commit('AtualizarCategorias', payload)
    },
    atualizarTipoContrato(context, payload) {
      context.commit('AtualizarTipoContrato', payload)
    },
    atualizarCredencial(context, payload) {
      context.commit('AtualizarCredencial', payload)
    },
    atualizarUsuario(context, payload) {
      context.commit('AtualizarUsuario', payload)
    },
    resetState(context) {
      context.commit('ResetState')
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
});
