<template>  
    <div class="loading-overlay">
        <div class="loading-panel">
            <div class="loading-spinner"></div>
            <h3 class="label-aguarde mb-3">Aguarde...</h3>
            <img src="../assets/images/logoPreta.png" alt="Logo" style="width:80px;" class="d-inline-block align-text-top"/>
        </div>
    </div>
</template>
<script>


  export default {
    name: "Loading"
  }
</script>

<style scoped>

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loading-panel {
  background-color: #fff;
  padding: 80px;
  padding-bottom:40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border:1px solid #37414f;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #37414f;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin-bottom: 10px;
}
.label-aguarde{
  color:#37414f;
}
</style>