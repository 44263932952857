<template>  
  <nav class="navbar navbar-expand bg-dark d-none d-md-block d-lg-block d-xl-block" v-if="exibeMenu">
  <div class="container-fluid">
    <NavbarHeader/>
    <div class="collapse navbar-collapse">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link" @click="validarMesmaRota('Inicio')" to="/Inicio">Início</router-link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Cadastro
          </a>
          <ul class="dropdown-menu">
            <li>
              <router-link class="dropdown-item" v-if="exibeCadUsuario" to="/cadUsuario">Usuário</router-link>
              <router-link class="dropdown-item" to="/cadPessoa">Pessoa</router-link>
              <router-link class="dropdown-item" to="/cadContrato">Contrato</router-link>
            </li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Relatórios
          </a>
          <ul class="dropdown-menu">
            <li>
              <router-link class="dropdown-item" to="/relatorioPessoa">Pessoa</router-link>
              <router-link class="dropdown-item" to="/RelatorioContrato">Contratos</router-link>
            </li>
          </ul>
        </li>
      </ul>
      <div class="dropdown dropstart">
        <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          Perfil
        </button>
        <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
          <li>
            <router-link class="dropdown-item" to="/AlterarSenha">Alterar Senha</router-link>
            <router-link class="dropdown-item"  @click="validarMesmaRota('Config')"  to="/Config" v-if="exibeConfig">Configuração</router-link>
          </li>
          <li><a class="dropdown-item" href="#" @click="sair()">Sair</a></li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<nav class="navbar navbar-dark bg-dark fixed-top d-block d-md-none d-lg-none d-xl-none" v-if="exibeMenu">
  <div class="container-fluid">    
    <NavbarHeader/>
     <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

  <div class="offcanvas offcanvas-end text-bg-dark bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header" style="margin-left:-25px;">
        <NavbarHeader/>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li class="nav-item">
            <router-link class="nav-link"  @click="validarMesmaRota('Inicio')" to="/Inicio">Início</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Cadastro
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" v-if="exibeCadUsuario" to="/cadUsuario">Usuário</router-link>
                <router-link class="dropdown-item" to="/cadPessoa">Pessoa</router-link>
                <router-link class="dropdown-item" to="/cadContrato">Contrato</router-link>
              </li>
            </ul>
          </li>

          
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Relatórios
            </a>
            <ul class="dropdown-menu">
              <li>
                <router-link class="dropdown-item" to="/relatorioPessoa">Pessoa</router-link>
                <router-link class="dropdown-item" to="/RelatorioContrato">Contratos</router-link>
              </li>
            </ul>
          </li>

          
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Perfil
            </a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
              <li>
                <router-link class="dropdown-item" to="/AlterarSenha">Alterar Senha</router-link>
                <router-link class="dropdown-item" to="/Config" @click="validarMesmaRota('Config')"  v-if="exibeConfig">Configuração</router-link>
              </li>
              <li><a class="dropdown-item" href="#" @click="sair()">Sair</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>

  </div>
</nav>


<notifications position="top center"/>
<router-view/>

<div style="padding-bottom:20px;#e0e0e0!important;">
</div>

</template>
<script>
import { isSignedIn ,tokenExpirado } from './router/auth';
import NavbarHeader from './components/NavbarHeader.vue';

export default {
    name: "App",
    components:{
      NavbarHeader
    },
    computed:{
      exibeMenu(){
        return tokenExpirado(this.$store.state.token);
      },
      exibeConfig(){
        return this.$store.state.idTipoUsuario == 2;
      },
      exibeCadUsuario(){
        return this.$store.state.idTipoUsuario == 2;
      }
    },
    mounted(){
      if(window.location.href.includes("/EmitirLembretes"))
        return;

      if(!this.logado()){
        this.$store.dispatch('resetState');
        this.$router.push('/login');
      }
    },
    methods: {
      validarMesmaRota(rotaIr){
        if(rotaIr == this.$route.name){
          this.$router.go(this.$router.currentRoute);
        }

      },
      logado()
      {
        return isSignedIn();
      },
      sair(){
        window.localStorage.setItem("token", '');
        this.$store.dispatch('atualizarCredencial', '');
        this.$router.push('/login')
        this.$store.dispatch('resetState');
      }
    }
}
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;*/
  background-color: #e0e0e0!important;
  min-height:100vh;
}
.nav-link, .navbar-brand{
  color:white !important;
}
.btn-perfil{
  border-color: white !important;
  color:white !important;
}
.btn-dark{
  border-color: white !important;
  color:white !important;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.nowrap{
  white-space:nowrap;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0.75rem;
}

.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
  opacity: 0.25;
}
.separator::before {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
  max-width: 10px;
  opacity: 0.25;
}


.separator:not(:empty)::after {
  margin-left: .25em;
}

.list-group.list-group-striped .list-group-item:not(.active):nth-of-type(even) {
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group.list-group-striped .list-group-item:not(.active):hover {
  background-color: var(--bs-list-group-action-active-bg);
}

/*


.btn-afirm {
  background-color: #37414f !important;
  color:#fbdb6b !important;
}
.btn-afirm2 {
  background-color: #222831 !important;
  color:white !important;
}
.btn-afirm:hover {
  background-color: #222831 !important;
  color:#fbdb6b !important;
}

.btn-afirm3 {
  background-color: #fbdb6b !important;
  color:#0d0f13 !important;
}

.btn-afirm4 {
  background-color: #FACF39 !important;
  color:#0d0f13 !important;
}

.btn-afirm5 {
  background-color: #fbdb6b !important;
  color:#37414f !important;
}

.btn-afirm6 {
  background-color: #37414f !important;
  color:white !important;
}
.btn-afirm3:hover {
  background-color: #FACF39 !important;
  color:#0d0f13 !important;
}
*/

.btn-primeiro {
  background-color: #37414f !important;
  color:white !important;
}
.btn-primeiro:hover {
  background-color: #0d0f13 !important;
  color:white !important;
}



.btn-secondario {
  background-color:white  !important;
  color:#37414f !important;
  border:1px solid #37414f !important;
}
.btn-secondario:hover {
  background-color: #0d0f13 !important;
  color:white !important;
}



.btn-terciario {
  background-color:white !important;
  color:#0d0f13 !important;
}
.btn-terciario:hover {
  background-color: #0d0f13 !important;
  color:white !important;
}

.bg-dark {
    background: linear-gradient(to right, #222831, #37414f) !important; /* Degrade */
}

.swal2-confirm{
  background-color: #37414f !important;
  color:white !important;
}
.swal2-confirm:hover {
  background-color: #0d0f13 !important;
  color:white !important;
}
.div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #0d0f13 !important;
}
</style>
