<template>
    <div class="row mb-2">
        <div class="col-12">
            <label for="novaSenha">{{ descricaoSenha }}</label>
            <input
                type="password"
                class="form-control"
                :class="classeCampos"
                id="novaSenha"
                v-model="novaSenha"
            />
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <label class="form-label form-label-sm" for="confirmacaoNovaSenha"
                >Confirmar {{ descricaoSenha }}</label
            >
            <input
                type="password"
                class="form-control"
                :class="classeCampos"
                id="confirmacaoNovaSenha"
                v-model="confirmacaoNovaSenha"
            />
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div v-if="minimoNumeros == true">
                <i class="fas fa-check" style="color: green" />
                <label style="margin-left: 15px">pelo menos um número</label>
            </div>
            <div v-else>
                <i class="fas fa-close" style="color: red" />
                <label style="margin-left: 15px">pelo menos um número</label>
            </div>
        </div>
        <div class="col-12">
            <div v-if="minimoLetras == true">
                <i class="fas fa-check" style="color: green" />
                <label style="margin-left: 15px">pelo menos uma letra</label>
            </div>
            <div v-else>
                <i class="fas fa-close" style="color: red" />
                <label style="margin-left: 15px">pelo menos uma letra</label>
            </div>
        </div>
        <div class="col-12">
            <div v-if="minimoCaracterEspecial == true">
                <i class="fas fa-check" style="color: green" />
                <label style="margin-left: 15px"
                    >pelo menos um caracter especial</label
                >
            </div>
            <div v-else>
                <i class="fas fa-close" style="color: red" />
                <label style="margin-left: 15px"
                    >pelo menos um caracter especial</label
                >
            </div>
        </div>
        <div class="col-12">
            <div v-if="minimoDigitos == true">
                <i class="fas fa-check" style="color: green" />
                <label style="margin-left: 15px">mínimo de 8 dígitos</label>
            </div>
            <div v-else>
                <i class="fas fa-close" style="color: red" />
                <label style="margin-left: 15px">mínimo de 8 dígitos</label>
            </div>
        </div>
        <div class="col-12">
            <div v-if="senhasIguais == true">
                <i class="fas fa-check" style="color: green" />
                <label style="margin-left: 15px">senhas iguais</label>
            </div>
            <div v-else>
                <i class="fas fa-close" style="color: red" />
                <label style="margin-left: 15px">senhas iguais</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Senha",
    components: {},
    data() {
        return {
            minimoDigitos: false,
            minimoCaracterEspecial: false,
            minimoNumeros: false,
            minimoLetras: false,
            senhasIguais: false,
            senhaValida: false,
        };
    },
    props: {
        novaSenha: {
            type: String,
            required: true,
            default: "",
        },
        confirmacaoNovaSenha: {
            type: String,
            required: true,
            default: "",
        },
        classeCampos: {
            type: String,
            required: false,
            default: "form-control-sm",
        },
        descricaoSenha: {
            type: String,
            required: false,
            default: "Senha",
        },
    },
    watch: {
        novaSenha: function (val) {
            const padraoLetras = /[a-zA-Z]/;
            const padraoNumero = /\d/;
            const padraoCaracterEspecial = /[^\w\s]/;
            this.minimoDigitos = val.length >= 8;
            this.minimoLetras = padraoLetras.test(val);
            this.minimoNumeros = padraoNumero.test(val);
            this.minimoCaracterEspecial = padraoCaracterEspecial.test(val);
            this.senhasIguais = this.novaSenha == this.confirmacaoNovaSenha;
        },
        confirmacaoNovaSenha: function (val) {
            this.senhasIguais = this.novaSenha == this.confirmacaoNovaSenha;
        },
    },
    methods: {
        ValidarSenha() {
            this.senhaValida =
                this.minimoDigitos &&
                this.minimoCaracterEspecial &&
                this.minimoNumeros &&
                this.minimoLetras &&
                this.senhasIguais;
            return this.senhaValida;
        },
    },
};
</script>