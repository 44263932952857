<template>
    <div id="div-main" class="container">
        <Loading v-if="isLoading" />
        <div class="row text-center">
            <h1 class="h3 mb-2 mt-2 font-weight-normal">Cadastro de Usuário</h1>
        </div>

        <hr />

        <div class="row">
            <div class="col-1"></div>
            <div class="col-lg-11 col-md-12">
                <div v-if="!novoCadastro" class="row">
                    <h6>Usuário de código: {{ idCadastro }}</h6>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <label for="email" class="form-label form-label-sm"
                            >E-mail</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="email"
                            placeholder="E-mail do Usuário"
                            v-model="email"
                            :disabled="!novoCadastro"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <label
                            for="numeroContrato"
                            class="form-label form-label-sm"
                            >Nome</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nome"
                            placeholder="Nome do Usuário"
                            v-model="nome"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <label
                            for="numeroContrato"
                            class="form-label form-label-sm"
                            >Celular/Telefone</label
                        >
                        <input
                            type="text"
                            class="form-control form-control-sm"
                            id="nome"
                            placeholder="Celular ou Telefone do Usuário"
                            v-model="telefone"
                            v-mask="['(##)#####-####']"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <label for="categoria" class="form-label form-label-sm"
                            >Tipo Usuário</label
                        >
                        <select
                            class="form-control form-control-sm"
                            v-model="tipoUsuario"
                        >
                            <option
                                v-for="cat in listaTipoUsuario"
                                :key="cat.id"
                                :value="cat.id"
                            >
                                {{ cat.descricao }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-12 ta-r d-grid d-md-block gap-2">
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="ReenviarSenha()"
                >
                    Reenviar Senha
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="AbrirHistorico()"
                >
                    Histórico
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="LimparCadastro()"
                >
                    Novo Cadastro
                </button>
                <button
                    type="button"
                    v-if="novoCadastro"
                    class="ml-2 btn btn-primeiro"
                    @click="CadastrarUsuario()"
                >
                    Cadastrar
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-primeiro"
                    @click="AlterarCadastro()"
                >
                    Salvar
                </button>
            </div>
        </div>

        <hr />

        <div class="row">
            <div class="col-12">
                <h5>Listagem de Usuários</h5>
            </div>
        </div>

        <div class="row" v-if="listagemUsuarios.length > 0">
            <div class="col-md-12 table-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Código</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th>Celular/Telefone</th>
                            <th>Excluido</th>
                            <th>-</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(usuario, index) in listagemUsuarios"
                            :key="index"
                        >
                            <td>{{ usuario.id }}</td>
                            <td>{{ usuario.nome }}</td>
                            <td>{{ usuario.email }}</td>
                            <td>{{ formataTelefone(usuario.telefone) }}</td>
                            <td>{{ formataBooleano(usuario.excluido) }}</td>
                            <td>
                                <button
                                    class="ml-2 btn btn-sm btn-secondario mb-1"
                                    @click="AbrirCadastro(usuario)"
                                >
                                    Editar
                                </button>
                                <button
                                    class="ml-2 btn btn-sm btn-secondario mb-1"
                                    v-if="
                                        usuario.excluido == false &&
                                        permissaoExcluirEncerrar
                                    "
                                    @click="ExcluirCadastro(usuario)"
                                >
                                    Excluir
                                </button>
                                <button
                                    class="ml-2 btn btn-sm btn-secondario mb-1"
                                    v-if="
                                        usuario.excluido == true &&
                                        permissaoExcluirEncerrar
                                    "
                                    @click="Reativar(usuario)"
                                >
                                    Reativar
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <modal-erp
            v-show="UiStatus.ModalHistorico"
            @close="FecharHistorico"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative  modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Histórico</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="FecharHistorico"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <table class="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th>Usuário Alterou</th>
                                <th>Data Alteração</th>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Celular/Telefone</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(result, index) in historico"
                                :key="index"
                            >
                                <td>
                                    {{
                                        retornarDescricaoUsuario(
                                            result.idUsuario
                                        )
                                    }}
                                </td>
                                <td>{{ formataData(result.data) }}</td>
                                <td>{{ result.nome }}</td>
                                <td>{{ result.email }}</td>
                                <td>{{ formataTelefone(result.telefone) }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </modal-erp>
    </div>
</template>

<script>
import Loading from "@/components/Loading.vue";
import ModalErp from "@/components/ModalErp.vue";
import formatMixin from "@/uteis/formatMixin.js";
import { getHeaders } from "../router/request";

export default {
    mixins: [formatMixin],
    name: "CadUsuario",
    components: {
        ModalErp,
        Loading,
    },
    data() {
        return {
            idCadastro: 0,
            nome: "",
            email: "",
            telefone: "",
            historico: [],
            tipoUsuario: 1,
            listaTipoUsuario: [
                { id: 1, descricao: "Usuário Comum" },
                { id: 2, descricao: "Administrador" },
            ],
            listagemUsuarios: [],
            UiStatus: {
                ModalHistorico: false,
            },
            isLoading: false,
        };
    },
    computed: {
        novoCadastro() {
            return this.idCadastro == 0;
        },
    },
    mounted() {
        if (!this.usuarioAdm) this.$router.push("/Inicio");
        this.ObterUsuarios();
    },
    methods: {
        CadastrarUsuario() {
            var self = this;
            this.isLoading = true;
            fetch(this.$store.state.url + "/Usuario/cadastrar.php", {
                method: "POST",
                body: JSON.stringify({
                    Email: this.email,
                    Nome: this.nome,
                    Telefone: this.telefone,
                    TipoUsuario: this.tipoUsuario,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.erro && data.erro && data.mensagem.length > 0) {
                        this.$swal(data.mensagem);
                        return;
                    }
                    this.NotificacaoSucesso("usuário cadastrado com sucesso.");
                    self.ObterUsuarios();
                    self.LimparCadastro();
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        AlterarCadastro() {
            var self = this;

            this.isLoading = true;
            fetch(this.$store.state.url + "/Usuario/alterar.php", {
                method: "POST",
                body: JSON.stringify({
                    Id: this.idCadastro,
                    Email: this.email,
                    Nome: this.nome,
                    Telefone: this.telefone,
                    TipoUsuario: this.tipoUsuario,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("usuário alterado com sucesso.");
                    self.ObterUsuarios();
                    self.LimparCadastro();
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        ExcluirCadastro(usuario) {
            var self = this;
            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Usuario/excluir.php?idUsuario=" +
                    usuario.id,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("usuário excluído com sucesso.");
                    self.ObterUsuarios();
                    self.LimparCadastro();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        Reativar(usuario) {
            var self = this;
            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Usuario/reativar.php?idUsuario=" +
                    usuario.id,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("usuário reativado com sucesso.");
                    self.ObterUsuarios();
                    self.LimparCadastro();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        ObterContratoCompleto(id) {
            this.isLoading = true;
            fetch(
                this.$store.state.url + "/Usuario/obter.php?idUsuario=" + id,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.AbrirCadastro(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        ObterUsuarios() {
            this.isLoading = true;
            fetch(this.$store.state.url + "/Usuario/obterTodos.php", {
                method: "GET",
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.AtualizarListagemUsuario(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        AbrirCadastro(cadastro) {
            this.idCadastro = cadastro.id;
            this.nome = cadastro.nome;
            this.email = cadastro.email;
            this.telefone = cadastro.telefone;
            this.historico = cadastro.historico;
            this.tipoUsuario = cadastro.idTipoUsuario;
        },
        SetarIdCadastro(id) {
            this.idCadastro = id;
        },
        LimparCadastro() {
            this.idCadastro = 0;
            this.nome = "";
            this.email = "";
            this.telefone = "";
            this.tipoUsuario = 1;
            this.historico = [];
        },
        AtualizarListagemUsuario(usuarios) {
            this.listagemUsuarios = usuarios;
        },
        FecharHistorico() {
            this.UiStatus.ModalHistorico = false;
        },
        AbrirHistorico() {
            this.UiStatus.ModalHistorico = true;
        },
        ReenviarSenha() {
            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Usuario/reenviarSenha.php?email=" +
                    this.email,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.AtualizarListagemUsuario(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },
};
</script>
<style scoped>
.row {
    margin-bottom: 10px;
}
.mt-2 {
    margin-top: 5px;
}
.ml-2 {
    margin-left: 5px;
}
.ta-l {
    text-align: left;
}
.ta-r {
    text-align: right;
}

#div-main {
    background-color: white;
    min-height: 100vh;
}
</style>