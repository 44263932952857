<template>
  <div>
      <Loading v-if="isLoading"/>
    <div class="row">
      <div class="col-lg-6 col-md-12" style="margin-bottom:5px;">
        <input type="text" class="form-control" v-model="searchText" placeholder="Pesquisar"  v-on:keyup.enter="PesquisarClientes">
        </div>
      <div class="col-lg-6 col-md-12 ta-r d-grid d-md-block gap-2">
        <button @click="PesquisarClientes" class="btn btn-primeiro ml-2">Pesquisar</button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-striped">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF/CNPJ</th>
            <th>Telefone</th>
            <th>E-mail</th>
            <th> - </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(result, index) in searchResults" :key="index">
            <td>{{ '(' + result.id + ') ' + result.descricao }}</td>
            <td>{{ mascaraCpfCnpj(result.cpfCnpj) }}</td>
            <td>{{ marcaraTelefone(result.telefone) }}</td>
            <td>{{ result.email }}</td>
            <td><button class="btn btn-sm btn-primeiro" @click="SelecionarPessoa(result)">Selecionar</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import { getHeaders } from './../router/request';
  import Loading from '@/components/Loading.vue'
  
export default {
	name: 'PesquisaCliente',
  components:{
    Loading
  },
  data() {
    return {
      searchText: "",
      searchResults: [],
      isLoading:false
    };
  },
  methods: {
    PesquisarClientes(){
      this.isLoading = true;
        fetch(this.$store.state.url + "/pessoa/pesquisa.php", {
            method: 'POST',
            body: JSON.stringify({
                "Texto": this.searchText
            }),
            headers: getHeaders()
            })
            .then(res =>res.json())
            .then(data => {
                this.searchResults = data;
                
            })
            .catch(err => console.log(err))
            .finally(() => {
          this.isLoading = false;
        });
        },
        SelecionarPessoa(pessoa){
            this.$emit("retornoSucesso", pessoa);
            this.searchText = '';
            this.searchResults = [];
        },
        mascaraCpfCnpj(texto){
            if(!texto)
                return;

            if(texto.length == 14)
                return texto.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            else
                return texto.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
        },
        marcaraTelefone(texto){
            return texto.replace(/^(\d{2})(\d{9})/, "($1) $2");
        }
    }
}
</script>
<style scoped>
.ml-2{
    margin-left: 2px;
}
.ta-r{
    text-align:right;
}
</style>