import { createRouter, createWebHistory } from 'vue-router'
import LoginComponent from '../views/Login.vue'
import CadContrato from '../views/CadContrato.vue'
import CadUsuario from '../views/CadUsuario.vue'

///import { isSignedIn } from './auth';

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/login",
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: LoginComponent
  },
  {
    path: "/cadContrato",
    name: "CadContrato",
    component: CadContrato
  },
  {
    path: "/cadUsuario",
    name: "CadUsuario",
    component: CadUsuario
  },
  {
    path: "/cadPessoa",
    name: "CadPessoa",
    component: () => import('../views/Pessoa/CadPessoa.vue')
  },
  {
    path: "/Usuario/Listagem",
    name: "ListagemUsuario",
    component: () => import('../views/ListagemUsuario.vue')
  },
  {
    path: "/RelatorioContrato",
    name: "RelatorioContrato",
    component: () => import('../views/relatorios/RelatorioContrato.vue')
  },
  {
    path: "/RelatorioPessoa",
    name: "RelatorioPessoa",
    component: () => import('../views/relatorios/RelatorioPessoa.vue')
  },
  {
    path: "/Inicio",
    name: "Inicio",
    component: () => import('../views/Inicio.vue')
  },
  {
    path: "/AlterarSenha",
    name: "AlterarSenha",
    component: () => import('../views/Login/AlterarSenha.vue')
  },
  {
    path: "/Config",
    name: "Config",
    component: () => import('../views/Config/Configuracao.vue')
  },
  {
    path: "/UploadArquivo",
    name: "UploadArquivo",
    component: () => import('../views/UploadArquivo.vue')
  },{
    path: "/EmitirLembretes",
    name: "EmitirLembretes",
    component: () => import('../views/publico/EmitirLembretes.vue')
  },
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router
