<template>
    <transition name="modal-fade" appear>
        <div
            :class="['modal-backdrop', classesAdicionaisBackDrop]"
            @keydown.esc="handleClose"
        >
            <div
                :class="[
                    'modal',
                    { 'fit-content': !telaInteira },
                    classesAdicionaisModal,
                    max960 ? 'm960' : 'm-3',
                ]"
                role="dialog"
                aria-labelledby="modalTitle"
                aria-describedby="modalDescription"
                tabindex="-1"
                style="overflow: hidden !important!"
            >
                <header :class="['modal-header', classesAdicionaisHeader]">
                    <slot name="header">
                        <span class="text-uppercase">{{ tituloModal }}</span>
                        <button
                            v-if="showBotaoFechar"
                            type="button"
                            id="lmxta-basemodal-btn-fechartopo"
                            class="btn-close p-0 float-right"
                            @click="handleClose"
                        >
                            <i class="fas fa-times" />
                        </button>
                    </slot>
                </header>
                <section
                    :class="[
                        'modal-body only-overflow-y',
                        classesAdicionaisBody,
                    ]"
                >
                    <slot name="body" />
                </section>
                <footer :class="['modal-footer', classesAdicionaisFooter]">
                    <slot name="footer">
                        <div class="col-6">
                            <button
                                type="button"
                                :id="idBotaoFechar"
                                class="btn btn-secondario rounded"
                                @click="handleClose"
                            >
                                Fechar
                            </button>
                        </div>
                        <div class="col-6 text-right px-2">
                            <button
                                v-if="showBotaoSalvar"
                                type="button"
                                class="btn btn-primeiro rounded"
                                :id="idBotaoSalvar"
                                @click="salvar()"
                            >
                                Salvar
                            </button>
                        </div>
                    </slot>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "BaseModal",
    props: {
        tituloModal: {
            type: String,
            required: false,
            default: "",
        },
        telaInteira: {
            type: Boolean,
            required: false,
            default: false,
        },
        classesAdicionaisBackDrop: {
            type: String,
            required: false,
            default: "",
        },
        classesAdicionaisModal: {
            type: String,
            required: false,
            default: "",
        },
        classesAdicionaisHeader: {
            type: String,
            required: false,
            default: "p-2 cabecalho-modal-customizado-lmx",
        },
        classesAdicionaisBody: {
            type: String,
            required: false,
            default: "",
        },
        classesAdicionaisFooter: {
            type: String,
            required: false,
            default: "",
        },
        idBotaoFechar: {
            type: String,
            required: false,
            default: "lmxta-basemodal-btn-fechar",
        },
        idBotaoSalvar: {
            type: String,
            required: false,
            default: "lmxta-basemodal-btn-salvar",
        },
        showBotaoFechar: {
            type: Boolean,
            default: true,
        },
        showBotaoSalvar: {
            type: Boolean,
            default: true,
        },
        max960: Boolean,
    },
    methods: {
        handleClose() {
            this.$emit("close");
        },
        salvar() {
            this.$emit("salvar");
        },
    },
};
</script>

<style scoped>
.modal-md {
    max-width: 420px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.cabecalho-modal-customizado-lmx {
    background-color: #5b2e90 !important;
    color: white !important;
    padding: 0.6rem !important;
}

.cabecalho-modal-customizado-lmx button {
    color: #fff !important;
}

.modal-content .no-padding {
    padding: 0rem !important;
}

.modal-dialog.modal-fluid {
    max-width: 90%;
    margin: 0.5rem auto;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.5s ease;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
}

.modal {
    background: #ffffff;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 2rem) !important;
    border-radius: 0.25rem;
}

.fit-content {
    height: fit-content !important;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    justify-content: flex-end;
}

.modal-body {
    position: relative;
    padding: 15px;
    overflow-y: auto;
}

.btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    background: transparent;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.only-overflow-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.max-w-700 {
    max-width: 700px;
}

.min-w-40 {
    min-width: 40%;
}

.modal-limitado {
    width: 100%;
    max-width: 320px;
}

.modal-sucesso {
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    border: none;
    border-radius: 0.3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
    text-align: center;
}
/*
. {
	position: relative;
	width: auto;
	margin: 0.5rem;
}*/

.modal-troca {
    max-height: none !important;
    width: 80% !important;
}

.modal-small {
    width: 50% !important;
}

.w-70 {
    width: 70% !important;
}

.m960 {
    max-width: 960px;
    margin: auto;
}

.h-90 {
    min-height: 90vh;
}

@media only screen and (max-width: 576px) {
    /*	. {
		margin: 1.75rem auto;
	}*/
    .modal-troca {
        max-height: none !important;
        width: 99% !important;
        margin: 0.1rem !important;
        margin-top: 1rem !important;
    }
    .modal-small {
        width: 75% !important;
    }
}
</style>
