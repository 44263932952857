<template>
    <div id="div-main" class="container">
        <Loading v-if="isLoading" />
        <div class="row text-center">
            <h1 class="h3 mb-2 mt-2 font-weight-normal">
                Cadastro de contrato
            </h1>
        </div>

        <hr />
        <div class="card-body">
            <div class="row">
                <div class="col-lg-1"></div>
                <div class="col-lg-10 col-sm-12">
                    <div class="row" v-if="!novoCadastro">
                        <div class="col-12">
                            <h5>
                                Contrato de código: {{ idCadastro }}
                                <span v-if="dadosContrato.encerrado">
                                    CONTRATO ENCERRADO</span
                                >
                            </h5>
                        </div>
                    </div>
                    <hr v-if="!novoCadastro" />

                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <label
                                for="categoria"
                                class="form-label form-label-sm"
                                >Categorias</label
                            >
                            <select
                                class="form-control form-control-sm"
                                v-model="dadosContrato.categoria"
                            >
                                <option
                                    v-for="cat in listaCategoriasOpcao"
                                    :key="cat.id"
                                    :value="cat.id"
                                >
                                    {{ cat.descricao }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <label
                                for="categoria"
                                class="form-label form-label-sm"
                                >Tipo do contrato</label
                            >
                            <select
                                class="form-control form-control-sm"
                                v-model="dadosContrato.idTipoContrato"
                            >
                                <option
                                    v-for="cat in listaTipoContratoOpcao"
                                    :key="cat.id"
                                    :value="cat.id"
                                >
                                    {{ cat.descricao }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <label
                                for="dtAssinatura"
                                class="form-label form-label-sm"
                                >Data da assinatura</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="dataVdtAssinaturaencimento"
                                placeholder="Data"
                                v-model="dadosContrato.dtAssinatura"
                                v-mask="'##/##/####'"
                            />
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-12">
                            <h5>Partes</h5>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <table class="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <td>
                                            <div
                                                class="d-flex justify-content-between"
                                            >
                                                <div>
                                                    {{
                                                        descricaoContratante(
                                                            dadosContrato.idTipoContrato
                                                        )
                                                    }}
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-primeiro m-1"
                                                        @click="
                                                            BuscarNovaPessoa(0)
                                                        "
                                                        title="Buscar cliente pelo texto informado"
                                                    >
                                                        <i
                                                            class="fa-solid fa-search"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-primeiro"
                                                        @click="
                                                            AdicionarNovaPessoa(
                                                                0
                                                            )
                                                        "
                                                        title="Adicionar uma nova pessoa"
                                                    >
                                                        <i
                                                            class="fa-solid fa-plus"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="pessoa in contratantes"
                                        :key="pessoa.id"
                                    >
                                        <td>
                                            <div
                                                class="d-flex justify-content-between"
                                            >
                                                <div>
                                                    {{ pessoa.descricao }}
                                                    (Documento:
                                                    {{ pessoa.cpfCnpj }})
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-terciarior"
                                                        @click="
                                                            RemoverPessoa(
                                                                pessoa,
                                                                0
                                                            )
                                                        "
                                                        title="Excluir contratante"
                                                    >
                                                        <i
                                                            class="fa-solid fa-trash"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-lg-6 col-sm-12">
                            <table class="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <td>
                                            <div
                                                class="d-flex justify-content-between"
                                            >
                                                <div>
                                                    {{
                                                        descricaoContratado(
                                                            dadosContrato.idTipoContrato
                                                        )
                                                    }}
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-primeiro m-1"
                                                        @click="
                                                            BuscarNovaPessoa(1)
                                                        "
                                                        title="Buscar cliente pelo texto informado"
                                                    >
                                                        <i
                                                            class="fa-solid fa-search"
                                                        ></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-primeiro"
                                                        @click="
                                                            AdicionarNovaPessoa(
                                                                1
                                                            )
                                                        "
                                                        title="Adicionar uma nova pessoa"
                                                    >
                                                        <i
                                                            class="fa-solid fa-plus"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="pessoa in contratados"
                                        :key="pessoa.id"
                                    >
                                        <td>
                                            <div
                                                class="d-flex justify-content-between"
                                            >
                                                <div>
                                                    {{ pessoa.descricao }}
                                                    (Documento:
                                                    {{ pessoa.cpfCnpj }})
                                                </div>
                                                <div>
                                                    <button
                                                        type="button"
                                                        class="btn btn-sm btn-terciarior"
                                                        @click="
                                                            RemoverPessoa(
                                                                pessoa,
                                                                1
                                                            )
                                                        "
                                                        title="Excluir contratante"
                                                    >
                                                        <i
                                                            class="fa-solid fa-trash"
                                                        ></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <label
                                for="vigenciaInicial"
                                class="form-label form-label-sm"
                                >Termo inicial</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="vigenciaInicial"
                                placeholder="Data"
                                v-model="vigenciaInicial"
                                v-mask="'##/##/####'"
                            />
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <label
                                for="vigenciaFinal"
                                class="form-label form-label-sm"
                                >Termo final</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="vigenciaFinal"
                                placeholder="Data"
                                v-model="vigenciaFinal"
                                :disabled="
                                    dadosContrato.flContratoTempoIndeterminado
                                "
                                v-mask="'##/##/####'"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-6" style="margin-top: 35px">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="
                                        dadosContrato.flContratoTempoIndeterminado
                                    "
                                    @click="alterouTempoIndeterminado()"
                                    id="flContratoTempoIndeterminado"
                                />
                                <label
                                    for="flContratoTempoIndeterminado"
                                    class="form-check-label form-check-label-sm"
                                    >Contrato por tempo indeterminado</label
                                >
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="renovacaoAutomatico"
                                    id="renovacaoAutomatico"
                                />
                                <label
                                    for="renovacaoAutomatico"
                                    class="form-check-label form-check-label-sm"
                                    >Renovação automático</label
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="assinatura"
                                    id="assinatura"
                                />
                                <label
                                    for="assinatura"
                                    class="form-check-label form-check-label-sm"
                                    >Assinatura digital</label
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="dadosContrato.flLgpd"
                                    id="flLgpd"
                                />
                                <label
                                    for="flLgpd"
                                    class="form-check-label form-check-label-sm"
                                    >LGPD</label
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="dadosContrato.flCompliance"
                                    id="flCompliance"
                                />
                                <label
                                    for="flCompliance"
                                    class="form-check-label form-check-label-sm"
                                    >Compliance</label
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="dadosContrato.flNda"
                                    id="flNda"
                                />
                                <label
                                    for="flNda"
                                    class="form-check-label form-check-label-sm"
                                    >NDA</label
                                >
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="form-check">
                                <input
                                    type="checkbox"
                                    class="form-check-input form-check-input-sm"
                                    v-model="dadosContrato.flEmailValido"
                                    id="flEmailValido"
                                />
                                <label
                                    for="flEmailValido"
                                    class="form-check-label form-check-label-sm"
                                    >E-mail válido</label
                                >
                            </div>
                        </div>
                    </div>

                    <hr />

                    <div class="row">
                        <div class="col-lg-8 col-6">
                            <label
                                for="observacoes"
                                class="form-label form-label-sm"
                                >Objeto</label
                            >
                            <input
                                type="text"
                                class="form-control"
                                id="deObjeto"
                                v-model="dadosContrato.deObjeto"
                                maxlength="200"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <label
                                for="vlContrato"
                                class="form-label form-label-sm"
                                >Valor do contrato</label
                            >
                            <money3
                                type="text"
                                class="form-control form-control-sm"
                                id="vlContrato"
                                v-bind="configMoney"
                                v-model="dadosContrato.vlContrato"
                                maxlength="20"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <textarea
                                    rows="3"
                                    class="form-control"
                                    id="observacoes"
                                    style="height: 100px"
                                    v-model="dadosContrato.observacoes"
                                    maxlength="500"
                                />
                                <label for="observacoes">Observações</label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="row mb-2"
                        v-if="dadosContrato.idTipoContrato == 2"
                    >
                        <div class="col-sm-4">
                            <label
                                for="metroQuadrado"
                                class="form-label form-label-sm"
                                >Metro quadrado(m²)</label
                            >
                            <money3
                                type="text"
                                class="form-control form-control-sm"
                                id="metroQuadrado"
                                placeholder="m²"
                                v-bind="configMoney"
                                v-model="dadosContrato.imovel.metroQuadrado"
                            />
                        </div>

                        <div class="col-sm-4">
                            <label
                                for="valorTotalMetro"
                                class="form-label form-label-sm"
                                >Valor total</label
                            >
                            <money3
                                type="text"
                                class="form-control form-control-sm"
                                id="valorTotalMetro"
                                v-bind="configMoney"
                                v-model="dadosContrato.imovel.valorTotalImovel"
                            />
                        </div>
                        <div class="col-sm-4">
                            <label
                                for="valorMetro"
                                class="form-label form-label-sm"
                                >Valor m²</label
                            >
                            <money3
                                type="text"
                                class="form-control form-control-sm"
                                id="valorMetro"
                                placeholder="valor m²"
                                v-bind="configMoney"
                                v-model="valorMetroQuadrado"
                                disabled
                                readonly
                            />
                        </div>
                    </div>
                    <div
                        class="row mb-2"
                        v-if="dadosContrato.idTipoContrato == 2"
                    >
                        <div class="col-lg-3 col-sm-10">
                            <div class="input-group">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="cep"
                                    placeholder="CEP"
                                    v-mask="'#####-###'"
                                    v-on:blur="ConsultarCep()"
                                    v-model="dadosContrato.imovel.cep"
                                />
                                <button
                                    class="btn btn-sm btn-outline-secondary"
                                    @click="ConsultarCep()"
                                >
                                    Consultar
                                </button>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-2">
                            <button
                                type="button"
                                class="btn btn-sm btn-secondario"
                                @click="AbrirNoMaps()"
                                title="Abrir no Maps"
                            >
                                Maps
                            </button>
                        </div>
                    </div>
                    <div
                        class="row mb-2"
                        v-if="dadosContrato.idTipoContrato == 2"
                    >
                        <div class="col-lg-2 col-sm-12">
                            <label for="estado" class="form-label form-label-sm"
                                >Estado</label
                            >
                            <select
                                class="form-control form-control-sm"
                                id="estado"
                                v-model="dadosContrato.imovel.estado"
                            >
                                <option
                                    v-for="uf in listagemOpcoes.ufs"
                                    :key="uf"
                                >
                                    {{ uf }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <label for="cidade" class="form-label form-label-sm"
                                >Cidade</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="cidade"
                                placeholder="Cidade"
                                v-model="dadosContrato.imovel.cidade"
                                maxlength="200"
                            />
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <label for="bairro" class="form-label form-label-sm"
                                >Bairro</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="bairro"
                                placeholder="Bairro"
                                v-model="dadosContrato.imovel.bairro"
                                maxlength="200"
                            />
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <label
                                for="logradouro"
                                class="form-label form-label-sm"
                                >Logradouro</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="logradouro"
                                placeholder="Logradouro"
                                v-model="dadosContrato.imovel.logradouro"
                                maxlength="500"
                            />
                        </div>
                        <div class="col-lg-2 col-sm-12">
                            <label
                                for="numeroEndereco"
                                class="form-label form-label-sm"
                                >Nº</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="numeroEndereco"
                                placeholder="Nº"
                                v-model="dadosContrato.imovel.numeroEndereco"
                                maxlength="50"
                            />
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <label
                                for="complemento"
                                class="form-label form-label-sm"
                                >Complemento</label
                            >
                            <input
                                type="text"
                                class="form-control form-control-sm"
                                id="complemento"
                                placeholder="Complemento"
                                v-model="dadosContrato.imovel.complemento"
                                maxlength="100"
                            />
                        </div>
                    </div>

                    <div
                        class="row mb-2"
                        v-if="dadosContrato.idTipoContrato == 2"
                    >
                        <div class="col-12">
                            <div class="form-floating mb-3">
                                <textarea
                                    rows="3"
                                    class="form-control"
                                    id="descricaoImovel"
                                    style="height: 60px"
                                    v-model="
                                        dadosContrato.imovel.descricaoImovel
                                    "
                                />
                                <label for="descricaoImovel" maxlength="500"
                                    >Descrição imóvel</label
                                >
                            </div>
                        </div>
                    </div>

                    <hr v-if="!novoCadastro && !dadosContrato.encerrado" />

                    <div class="row" v-if="!novoCadastro">
                        <div class="col-8">
                            <h5>Arquivos</h5>
                        </div>
                        <div
                            class="col-4 text-end"
                            v-if="!novoCadastro && !dadosContrato.encerrado"
                        >
                            <button
                                type="button"
                                v-if="!novoCadastro"
                                class="ml-2 btn btn-sm btn-primeiro"
                                @click="UiStatus.ModalIncluirArquivos = true"
                            >
                                Incluir novos arquivos
                            </button>
                        </div>

                        <div class="row">
                            <div
                                class="col-lg-4 col-6"
                                v-for="arquivo in arquivos"
                                :key="arquivo.idContratoArquivo"
                            >
                                <div
                                    class="card"
                                    v-if="!arquivo.idUsuarioExcluiu"
                                >
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-lg-10 col-sm-12">
                                                Nome
                                                {{ arquivo.nomeArquivoUsuario }}
                                                <br />
                                                Incluído
                                                {{
                                                    formataDataHora(
                                                        arquivo.dataCriacao
                                                    )
                                                }}
                                                <br />
                                                Pelo usuário
                                                {{
                                                    retornarDescricaoUsuario(
                                                        arquivo.idUsuario
                                                    )
                                                }}
                                            </div>
                                            <div class="col-lg-2 col-sm-12">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-terciarior"
                                                    style="margin-bottom: 10px"
                                                    @click="
                                                        excluirArquivo(
                                                            arquivo.idContratoArquivo
                                                        )
                                                    "
                                                    title="Excluir Arquivo"
                                                >
                                                    <i
                                                        class="fa-solid fa-trash"
                                                    ></i>
                                                </button>
                                                <br />
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-primeiro"
                                                    @click="
                                                        baixarArquivo(arquivo)
                                                    "
                                                    title="Baixar Arquivo"
                                                >
                                                    <i
                                                        class="fa-solid fa-circle-down"
                                                    ></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr v-if="!novoCadastro" />

                    <div class="row" v-if="!novoCadastro">
                        <div class="col-11">
                            <h5>Comentários</h5>
                        </div>
                        <div class="col-1">
                            <button
                                type="button"
                                class="btn btn-sm"
                                v-if="!esconderComentarios"
                                @click="esconderComentarios = true"
                            >
                                <i class="fa-solid fa-eye-slash"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm"
                                v-if="esconderComentarios"
                                @click="esconderComentarios = false"
                            >
                                <i class="fa-solid fa-eye"></i>
                            </button>
                        </div>
                    </div>
                    <div class="row" v-if="!esconderComentarios">
                        <div class="col-11">
                            <div class="form-floating mb-3">
                                <textarea
                                    rows="3"
                                    class="form-control"
                                    id="novoComentario"
                                    placeholder="Comentário"
                                    v-model="novoComentario"
                                    maxlength="300"
                                />
                                <label for="novoComentario"
                                    >Novo Comentário</label
                                >
                            </div>
                        </div>
                        <div class="col-1">
                            <button
                                type="button"
                                class="btn btn-sm btn-primeiro"
                                @click="NovoComentario()"
                            >
                                <i class="fa-solid fa-plus"></i>
                            </button>
                        </div>
                    </div>

                    <div class="list-group" v-if="!esconderComentarios">
                        <li
                            class="list-group-item"
                            v-for="mensagem in mensagens"
                            :key="mensagem.id"
                        >
                            <div class="d-flex w-100 justify-content-between">
                                <small class="fw-light">{{
                                    retornarNomeUsuario(
                                        mensagem.idUsuarioAlterou
                                    )
                                }}</small>
                                <small class="fw-light">{{
                                    formataData(mensagem.dataInclusao)
                                }}</small>
                            </div>
                            <label>{{ mensagem.mensagem }}</label>
                        </li>
                    </div>

                    <hr v-if="!novoCadastro" />

                    <div class="row" v-if="!novoCadastro">
                        <div class="col-12">
                            <h5>Avisos</h5>
                        </div>
                    </div>

                    <div
                        class="d-block d-md-none d-lg-none d-xl-none"
                        v-if="!novoCadastro"
                    >
                        <div class="row">
                            <div class="col-6">
                                <label
                                    for="dtLembrete"
                                    class="form-label form-label-sm"
                                    >Data do aviso</label
                                >
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="dtLembrete"
                                    placeholder="Data do lembrete"
                                    v-model="dadosContrato.dtLembrete"
                                    v-mask="'##/##/####'"
                                />
                            </div>
                            <!--
                            <div class="col-6">
                                <label for="emailLembrete" class="form-label form-label-sm">E-mail do lembrete</label>
                                <input type="text" class="form-control form-control-sm"  id="emailLembrete" placeholder="E-mail do lembrete"
                                v-model="dadosContrato.emailLembrete">
                            </div>
                            -->
                            <div class="col-6">
                                <label class="form-label form-label-sm"
                                    >Descrição</label
                                >
                                <input
                                    type="text"
                                    rows="3"
                                    class="form-control form-control-sm"
                                    id="descricaoLembrete"
                                    placeholder="Descrição"
                                    v-model="dadosContrato.descricaoLembrete"
                                />
                            </div>
                            <div class="col-6 d-grid">
                                <label> &nbsp;</label>
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primeiro"
                                    title="Adicionar lembrete"
                                    @click="adicionarLembrete()"
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        class="d-none d-md-block d-lg-block d-xl-block"
                        v-if="!novoCadastro"
                    >
                        <div class="row">
                            <div class="col-lg-2">
                                <input
                                    type="text"
                                    class="form-control form-control-sm"
                                    id="dtLembrete"
                                    placeholder="Data do lembrete"
                                    v-model="dadosContrato.dtLembrete"
                                    v-mask="'##/##/####'"
                                />
                            </div>
                            <!--
                            <div class="col-lg-4">
                                <input type="text" class="form-control form-control-sm"  id="emailLembrete" placeholder="E-mail do lembrete"
                                v-model="dadosContrato.emailLembrete">
                            </div>
                            -->
                            <div class="col-lg-9">
                                <input
                                    type="text"
                                    rows="3"
                                    class="form-control form-control-sm"
                                    id="descricaoLembrete"
                                    placeholder="Descrição"
                                    v-model="dadosContrato.descricaoLembrete"
                                />
                            </div>
                            <div class="col-1">
                                <button
                                    type="button"
                                    class="btn btn-sm btn-primeiro"
                                    title="Adicionar lembrete"
                                    @click="adicionarLembrete()"
                                >
                                    Adicionar
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="list-group" v-if="!novoCadastro">
                        <li
                            class="list-group-item"
                            v-for="lembrete in lembretes"
                            :key="lembrete.id"
                        >
                            <div class="row">
                                <div class="col-4">
                                    Data: {{ formataData(lembrete.dtLembrete) }}
                                </div>
                                <!--
                                <div class="col-lg-4 col-8">
                                    E-mail: {{ lembrete.emailLembrete}}
                                </div>-->
                                <div class="col-6">
                                    Descrição: {{ lembrete.descricaoLembrete }}
                                </div>
                                <div class="col-2">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-terciarior"
                                        v-if="podeExcluirAviso(lembrete)"
                                        @click="excluirLembrete(lembrete.id)"
                                    >
                                        <i class="fa-solid fa-trash-can"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
            </div>
        </div>

        <hr v-if="!novoCadastro" />

        <div class="row">
            <div
                class="col-lg-1 col-md-12 d-grid d-md-block gap-2"
                v-if="propVoltar != null"
                style="margin-bottom: 8px"
            >
                <button
                    type="button"
                    class="ml-2 btn btn-secondario"
                    @click="voltar()"
                >
                    Voltar
                </button>
            </div>
            <div class="col-lg-11 col-md-12 ta-r d-grid d-md-block gap-2">
                <button
                    type="button"
                    v-if="
                        !novoCadastro &&
                        !dadosContrato.encerrado &&
                        permissaoExcluirEncerrar
                    "
                    class="ml-2 btn btn-terciarior"
                    @click="Encerrar()"
                >
                    Encerrar
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="AbrirHistorico()"
                >
                    Histórico
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="AbrirHistoricoArquivos()"
                >
                    Arquivos
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro"
                    class="ml-2 btn btn-secondario"
                    @click="LimparCadastro()"
                >
                    Novo Cadastro
                </button>
                <button
                    type="button"
                    v-if="novoCadastro"
                    class="ml-2 btn btn-primeiro"
                    @click="CadastrarContrato()"
                >
                    Cadastrar
                </button>
                <button
                    type="button"
                    v-if="!novoCadastro && !dadosContrato.encerrado"
                    class="ml-2 btn btn-primeiro"
                    @click="AlterarCadastro()"
                >
                    Salvar
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="margin-bottom: 15px"></div>
        </div>

        <modal-erp
            v-show="UiStatus.ModalPessoa"
            @close="fecharModal"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative  modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Nova pessoa</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="fecharModal"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <CadPessoa
                        ref="cadPessoa"
                        @retornoSucesso="retornoSucessoCadastroCliente"
                    />
                </div>
            </template>
        </modal-erp>

        <modal-erp
            v-show="UiStatus.ModalPesquisaCliente"
            @close="fecharModalPesquisaPessoa"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative  modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Pesquisa pessoas</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="fecharModalPesquisaPessoa"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <ConsultaCliente
                        ref="pesquisaCliente"
                        @retornoSucesso="retornoSucessoPesquisaCliente"
                    />
                </div>
            </template>
        </modal-erp>

        <modal-erp
            v-show="UiStatus.ModalHistorico"
            @close="FecharHistorico"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative  modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Histórico</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="FecharHistorico"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body no-padding table-responsive">
                    <historico-card
                        v-show="!novoCadastro"
                        :historicoContrato="contratoHistorico"
                    />
                </div>
            </template>
        </modal-erp>

        <modal-erp
            v-show="UiStatus.ModalHistoricoArquivos"
            @close="FecharHistoricoArquivos"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative  modal-troca'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Histórico arquivos</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="FecharHistoricoArquivos"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th>Usuário</th>
                                <th>Data incluído</th>
                                <th>Nome</th>
                                <th>Data exclusão</th>
                                <th>Usuário excluiu</th>
                                <th>Arquivo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(result, index) in arquivos"
                                :key="index"
                            >
                                <td>
                                    {{
                                        retornarDescricaoUsuario(
                                            result.idUsuario
                                        )
                                    }}
                                </td>
                                <td>
                                    {{ formataDataHora(result.dataCriacao) }}
                                </td>
                                <td>{{ result.nomeArquivoUsuario }}</td>
                                <td>
                                    {{
                                        result.dtExclusao == ""
                                            ? "-"
                                            : formataDataHora(result.dtExclusao)
                                    }}
                                </td>
                                <td>
                                    {{
                                        retornarDescricaoUsuario(
                                            result.idUsuarioExcluiu
                                        )
                                    }}
                                </td>
                                <td>
                                    <button
                                        class="btn bt-small btn-secondario"
                                        @click="baixarArquivo(result)"
                                        v-if="!result.idUsuarioExcluiu"
                                    >
                                        Download
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </modal-erp>

        <modal-erp
            v-show="UiStatus.ModalIncluirArquivos"
            @close="UiStatus.ModalIncluirArquivos = false"
            :classesAdicionaisModal="'rounded col-sm-7 position-relative modal-small'"
            :classesAdicionaisHeader="'d-none'"
            :classesAdicionaisFooter="'d-none'"
            :classesAdicionaisBody="'py-2'"
            style="overflow-y: scroll"
        >
            <template #body>
                <div class="modal-header">
                    <h5 class="text-uppercase">Incluir Arquivos</h5>
                    <button
                        class="close btn btn-secondario"
                        @click="UiStatus.ModalIncluirArquivos = false"
                        aria-label="Fechar"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body no-padding">
                    <div class="col-12 text-center" style="margin: 5px">
                        <input
                            type="file"
                            @change="handleFileUpload"
                            class="form-control"
                            multiple
                            ref="fileupload"
                        />
                    </div>
                    <div class="col-12 text-end">
                        <button
                            type="button"
                            class="ml-2 btn btn-primeiro"
                            @click="EnviarArquivos()"
                        >
                            Salvar Arquivos
                        </button>
                    </div>
                </div>
            </template>
        </modal-erp>
    </div>
</template>

<script>
import HistoricoCard from "@/components/ContratoHistoricoCard.vue";
import Loading from "@/components/Loading.vue";
import ModalErp from "@/components/ModalErp.vue";
import ConsultaCliente from "@/components/PesquisaCliente.vue";
import formatMixin from "@/uteis/formatMixin.js";
import axios from "axios";
import { Money3Component } from "v-money3";
import { getHeaders } from "./../router/request";
import CadPessoa from "./Pessoa/CadPessoa.vue";

export default {
    mixins: [formatMixin],
    name: "CadContrato",
    components: {
        CadPessoa,
        ModalErp,
        ConsultaCliente,
        money3: Money3Component,
        HistoricoCard,
        Loading,
    },
    data() {
        return {
            listagemOpcoes: {
                ufs: [
                    "AC",
                    "AL",
                    "AP",
                    "AM",
                    "BA",
                    "CE",
                    "DF",
                    "ES",
                    "GO",
                    "MA",
                    "MT",
                    "MS",
                    "MG",
                    "PA",
                    "PB",
                    "PR",
                    "PE",
                    "PI",
                    "RJ",
                    "RN",
                    "RS",
                    "RO",
                    "RR",
                    "SC",
                    "SP",
                    "SE",
                    "TO",
                ],
            },
            contratoHistorico: [],
            contratoSelecionado: null,
            arquivos: [],
            arquivosNovo: [],
            idCadastro: 0,
            vigenciaInicial: null,
            vigenciaFinal: null,
            textoBuscaCliente: "",
            clientesContrato: [],
            assinatura: false,
            renovacaoAutomatico: false,
            contratantes: [],
            contratados: [],
            listaCategoriasOpcao: [],
            listaTipoContratoOpcao: [],
            novoComentario: "",
            mensagens: [],
            lembretes: [],
            files: new FormData(),
            UiStatus: {
                ModalPessoa: false,
                ModalPesquisaCliente: false,
                ModalHistorico: false,
                ModalHistoricoArquivos: false,
                ModalIncluirArquivos: false,
            },
            esconderComentarios: true,
            tipoPessoaBuscaOuCadastro: 0,
            retornaSucesso: true,
            dadosContrato: {
                encerrado: false,
                categoria: 1,
                tipoContrato: 1,
                deObjeto: "",
                vlContrato: 0,
                idTipoContrato: 1,
                dtAssinatura: null,
                flContratoTempoIndeterminado: 0,
                flLgpd: 0,
                flCompliance: 0,
                flNda: 0,
                flEmailValido: 0,
                observacoes: "",
                imovel: {
                    metroQuadrado: 0,
                    valorTotalImovel: 0,
                    descricaoImovel: "",
                    logradouro: "",
                    cep: "",
                    numeroEndereco: "",
                    cidade: "",
                    estado: "",
                    bairro: "",
                    complemento: "",
                },
                dtLembrete: "",
                emailLembrete: "",
                descricaoLembrete: "",
            },
            configMoney: {
                masked: false,
                prefix: "",
                suffix: "",
                thousands: ",",
                decimal: ".",
                precision: 2,
                disableNegative: false,
                disabled: false,
                min: null,
                max: null,
                allowBlank: false,
                minimumNumberOfCharacters: 0,
                shouldRound: true,
                focusOnRight: false,
            },
            isLoading: false,
        };
    },
    props: {
        propVoltar: {
            type: Function,
            default: null,
        },
    },
    computed: {
        novoCadastro() {
            return this.idCadastro == 0;
        },
        valorMetroQuadrado() {
            return (
                this.dadosContrato.imovel.valorTotalImovel /
                this.dadosContrato.imovel.metroQuadrado
            );
        },
    },
    mounted() {
        this.listaCategoriasOpcao = this.$store.state.listaCategorias;
        this.listaTipoContratoOpcao = this.$store.state.listaTipoContrato;

        if (this.novoCadastro) this.dadosContrato.categoria = 2;
    },
    methods: {
        voltar() {
            if (this.propVoltar != null) this.propVoltar();
        },
        handleFileUpload(event) {
            this.arquivosNovo = event.target.files;
        },
        EnviarArquivos() {
            this.isLoading = true;
            let data = new FormData();
            for (let i = 0; i < this.arquivosNovo.length; i++) {
                data.append("arquivos[]", this.arquivosNovo[i]);
            }
            axios
                .post(
                    this.$store.state.url +
                        "/Contrato/arquivo.php?idContrato=" +
                        this.idCadastro,
                    data,
                    { headers: getHeaders() }
                )
                .then((response) => {
                    console.log(response.data);
                    this.arquivos = response.data;
                    this.arquivosNovo = [];
                    this.$refs.fileupload.value = null;
                    this.UiStatus.ModalIncluirArquivos = false;
                    this.NotificacaoSucesso("Arquivo enviado com sucesso.");
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        NovoComentario() {
            if (this.novoComentario == "" || this.novoComentario.length == 0) {
                this.NotificacaoAlerta(
                    "Informe algum comentario para adicionar."
                );
                return;
            }

            this.isLoading = true;

            fetch(this.$store.state.url + "/Contrato/incluirMensagem.php", {
                method: "POST",
                body: JSON.stringify({
                    IdContrato: this.idCadastro,
                    Mensagem: this.novoComentario,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.mensagens.unshift(data);
                    this.novoComentario = "";
                    this.NotificacaoSucesso(
                        "Comentário adicionado com sucesso."
                    );
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        adicionarLembrete() {
            if (this.dadosContrato.dtLembrete == "") {
                this.NotificacaoAlerta("Informe a data do lembrete.");
                return;
            }
            /*
        if(this.dadosContrato.emailLembrete == ''){
            this.NotificacaoAlerta("Informe o e-mail do lembrete.");
            return;
        }*/

            if (this.dadosContrato.descricaoLembrete == "") {
                this.NotificacaoAlerta("Informe a descrição do lembrete.");
                return;
            }

            this.isLoading = true;

            fetch(this.$store.state.url + "/Contrato/incluirLembrete.php", {
                method: "POST",
                body: JSON.stringify({
                    IdContrato: this.idCadastro,
                    DtLembrete: this.dadosContrato.dtLembrete,
                    EmailLembrete: this.dadosContrato.emailLembrete,
                    DescricaoLembrete: this.dadosContrato.descricaoLembrete,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.lembretes.unshift(data);
                    this.dadosContrato.dtLembrete = "";
                    this.dadosContrato.emailLembrete = "";
                    this.dadosContrato.descricaoLembrete = "";
                    this.NotificacaoSucesso("Lembrete adicionado com sucesso.");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        excluirLembrete(idLembrete) {
            var resultado = confirm("Deseja realmente excluir o lembrete?");
            if (resultado != true) return;

            this.isLoading = true;
            fetch(this.$store.state.url + "/Contrato/excluirLembrete.php", {
                method: "POST",
                body: JSON.stringify({
                    Id: idLembrete,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("lembrete excluido com sucesso.");
                    this.lembretes = this.lembretes.filter(
                        (l) => l.id !== idLembrete
                    );
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        excluirArquivo(idContratoArquivo) {
            var resultado = confirm("Deseja realmente excluir o arquivo?");
            if (resultado != true) return;

            this.isLoading = true;
            fetch(this.$store.state.url + "/Contrato/excluirArquivo.php", {
                method: "POST",
                body: JSON.stringify({
                    Id: idContratoArquivo,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("arquivo excluido com sucesso.");
                    this.arquivos = this.arquivos.filter(
                        (a) => a.idContratoArquivo !== idContratoArquivo
                    );
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        ObterContratoCompleto(idContrato) {
            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Contrato/obter.php?idContrato=" +
                    idContrato,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    this.abrirContratoDados(data);
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async CadastrarContrato() {
            let msgValidacao = this.validarContrato();
            if (msgValidacao != "") {
                this.NotificacaoAlerta(msgValidacao);
                return;
            }

            this.isLoading = true;
            var obterContrato = this.ObterContratoDTO();
            fetch(this.$store.state.url + "/Contrato/cadastrar.php", {
                method: "POST",
                body: JSON.stringify(obterContrato),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error && data.error) {
                        this.$swal(data.error);
                        return;
                    }
                    this.setarIdContrato(data.idContrato);
                    this.lembretes = data.lembretes;
                    this.NotificacaoSucesso("Contrato cadatrado com sucesso.");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        validarContrato() {
            if (
                this.contratantes == null ||
                this.contratantes == [] ||
                this.contratantes.length == 0
            )
                return "Informe pelo menos um contratante.";

            if (
                this.contratados == null ||
                this.contratados == [] ||
                this.contratados.length == 0
            )
                return "Informe pelo menos um contratado.";

            if (!this.validarDataFormato(this.dadosContrato.dtAssinatura))
                return "Informe corretamente a data de assinatura.";

            if (
                this.dadosContrato.deObjeto == null ||
                this.dadosContrato.deObjeto == ""
            )
                return "O campo objeto é obrigatório.";

            if (!this.validarDataFormato(this.vigenciaInicial))
                return "Informe corretamente o campo vigência inicial.";

            if (
                !this.validarDataFormato(this.vigenciaFinal) &&
                this.dadosContrato.flContratoTempoIndeterminado == false
            )
                return "Informe corretamente o campo vigência final.";

            if (
                this.dadosContrato.flContratoTempoIndeterminado == false &&
                this.dataUmMaiorQueDataDois(
                    this.vigenciaInicial,
                    this.vigenciaFinal
                )
            )
                return "A vigência final deve ser maior que a inicial.";

            return "";
        },
        ObterContratoDTO() {
            var idsCotratantes = this.contratantes.map((c) => parseInt(c.id));
            var idsContratados = this.contratados.map((c) => parseInt(c.id));

            return {
                DtAssinatura: this.dadosContrato.dtAssinatura,
                IdsContratante: idsCotratantes,
                IdsContratados: idsContratados,
                IdCategoria: this.dadosContrato.categoria,
                FlagAssinatura: this.assinatura,
                FlagRenovacaoAutomatico: this.renovacaoAutomatico,
                VigenciaInicial: this.vigenciaInicial,
                VigenciaFinal: this.vigenciaFinal,
                Observacoes: this.dadosContrato.observacoes ?? "",
                MetroQuadrado: this.dadosContrato.imovel.metroQuadrado,
                DescricaoImovel:
                    this.dadosContrato.imovel.descricaoImovel ?? "",
                ValorTotalImovel: this.dadosContrato.imovel.valorTotalImovel,
                VlMetroQuadrado: this.valorMetroQuadrado,
                Cep: this.dadosContrato.imovel.cep,
                Estado: this.dadosContrato.imovel.estado,
                Cidade: this.dadosContrato.imovel.cidade,
                Bairro: this.dadosContrato.imovel.bairro,
                Logradouro: this.dadosContrato.imovel.logradouro,
                NumeroEndereco: this.dadosContrato.imovel.numeroEndereco,
                Complemento: this.dadosContrato.imovel.complemento,
                FlagComplice: this.dadosContrato.flCompliance,
                FlagLgpd: this.dadosContrato.flLgpd,
                FlagContratoTempoIndeterminado:
                    this.dadosContrato.flContratoTempoIndeterminado,
                FlagNda: this.dadosContrato.flNda,
                FlagEmailValido: this.dadosContrato.flEmailValido,
                VlContrato: this.dadosContrato.vlContrato,
                IdTipoContrato: this.dadosContrato.idTipoContrato,
                DeObjeto: this.dadosContrato.deObjeto,
            };
        },
        async AlterarCadastro() {
            this.isLoading = true;

            var obterContrato = this.ObterContratoDTO();
            obterContrato.Id = this.idCadastro;
            fetch(this.$store.state.url + "/Contrato/alterar.php", {
                method: "POST",
                body: JSON.stringify(obterContrato),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data.error && data.error) {
                        this.$swal(data.error);
                        return;
                    }
                    this.setarIdContrato(data.idContrato);
                    this.contratoHistorico = data.historico;
                    this.NotificacaoSucesso("Contrato alterado com sucesso.");
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        async Encerrar() {
            var resultado = confirm("Deseja encerrar o contrato?");
            if (resultado != true) return;

            this.isLoading = true;
            fetch(this.$store.state.url + "/Contrato/encerrar.php", {
                method: "POST",
                body: JSON.stringify({
                    Id: this.idCadastro,
                }),
                headers: getHeaders(),
            })
                .then((res) => res.json())
                .then((data) => {
                    this.NotificacaoSucesso("Contrato encerrado com sucesso.");
                    this.dadosContrato.encerrado = true;
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isLoading = false;
                });
        },
        fecharModalPesquisaPessoa() {
            this.UiStatus.ModalPesquisaCliente = false;
            this.$refs.pesquisaCliente.searchText = "";
            this.$refs.pesquisaCliente.searchResults = [];
        },
        FecharHistoricoArquivos() {
            this.UiStatus.ModalHistoricoArquivos = false;
        },
        FecharHistorico() {
            this.UiStatus.ModalHistorico = false;
        },
        AbrirHistoricoArquivos() {
            this.UiStatus.ModalHistoricoArquivos = true;
        },
        AbrirHistorico() {
            this.UiStatus.ModalHistorico = true;
        },
        baixarArquivo(arquivo) {
            this.isLoading = true;
            fetch(
                this.$store.state.url +
                    "/Contrato/obterArquivo.php?idContratoArquivo=" +
                    arquivo.idContratoArquivo,
                {
                    method: "GET",
                    headers: getHeaders(),
                }
            )
                .then((response) => {
                    // Verifica se a resposta da requisição foi bem sucedida.
                    if (!response.ok) {
                        throw new Error("Falha ao fazer download do arquivo.");
                    }
                    // Cria um objeto Blob com o conteúdo do arquivo.
                    return response.blob();
                })
                .then((blob) => {
                    const url = URL.createObjectURL(blob);
                    // Cria um link temporário para o arquivo.
                    const link = document.createElement("a");
                    link.href = url;
                    link.download = "Contrato" + arquivo.caminhoArquivo;
                    // Simula um clique no link para iniciar o download.
                    link.click();
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        BaixarUltimoArquivo() {
            if (!this.arquivos.length) return;

            this.baixarArquivo(this.arquivos[0]);
        },
        BuscarNovaPessoa(tipo) {
            this.tipoPessoaBuscaOuCadastro = tipo;
            this.UiStatus.ModalPesquisaCliente = true;
        },
        AdicionarNovaPessoa(tipo) {
            this.tipoPessoaBuscaOuCadastro = tipo;
            this.UiStatus.ModalPessoa = true;
            this.$refs.cadPessoa.RetornarSucesso(true);
        },
        onFileChange(e) {
            var self = this;
            if (!e.target.files.length) return;
            self.files = e.target.files;
        },
        AdicionarContratantes(pessoa) {
            if (
                this.contratantes.filter((p) => p.id == pessoa.id).length != 0
            ) {
                this.NotificacaoAlerta("Contratante já incluido.");
                return;
            }
            this.contratantes.push(pessoa);
        },
        AdicionarContratados(pessoa) {
            if (this.contratados.filter((p) => p.id == pessoa.id).length != 0) {
                this.NotificacaoAlerta("Contratante já incluido.");
                return;
            }
            this.contratados.push(pessoa);
        },
        RemoverPessoa(pessoa, tipo) {
            if (tipo == 0) {
                this.contratantes = this.contratantes.filter(
                    (p) => p.id !== pessoa.id
                );
            } else {
                this.contratados = this.contratados.filter(
                    (p) => p.id !== pessoa.id
                );
            }
        },
        abrirContrato(contrato) {
            this.ObterContratoCompleto(contrato.idContrato);
        },
        abrirContratoDados(contrato) {
            this.contratoSelecionado = contrato;
            this.idCadastro = contrato.idContrato;

            this.arquivos = contrato.arquivos;
            this.contratantes = contrato.contratantes;
            this.contratados = contrato.contratados;
            this.contratoHistorico = contrato.historico;
            this.validadoJuridico = contrato.validadoJuridico;
            this.validadoComercial = contrato.validadoComercial;
            this.assinatura = contrato.assinatura;
            this.renovacaoAutomatico = contrato.renovacaoAutomatico;
            this.mensagens = contrato.mensagens;
            this.lembretes = contrato.lembretes;

            this.dadosContrato.dtAssinatura = this.formataData(
                contrato.dtAssinatura
            );
            this.vigenciaFinal = this.formataData(contrato.vigenciaFinal);
            this.vigenciaInicial = this.formataData(contrato.vigenciaInicial);

            this.dadosContrato.encerrado = contrato.encerrado;
            this.dadosContrato.categoria = contrato.idCategoria;
            this.dadosContrato.imovel.metroQuadrado = contrato.metroQuadrado;
            this.dadosContrato.imovel.valorTotalImovel =
                contrato.valorTotalImovel;
            this.dadosContrato.imovel.descricaoImovel =
                contrato.descricaoImovel;
            this.dadosContrato.imovel.cep =
                contrato.cep != null ? contrato.cep.replace(/[^0-9]/g, "") : "";
            this.dadosContrato.imovel.estado = contrato.estado;
            this.dadosContrato.imovel.cidade = contrato.cidade;
            this.dadosContrato.imovel.bairro = contrato.bairro;
            this.dadosContrato.imovel.logradouro = contrato.logradouro;
            this.dadosContrato.imovel.numeroEndereco = contrato.numeroEndereco;
            this.dadosContrato.imovel.complemento = contrato.complemento;
            this.dadosContrato.observacoes = contrato.observacao;

            this.dadosContrato.vlContrato = contrato.vlContrato;
            this.dadosContrato.idTipoContrato = contrato.idTipoContrato;
            this.dadosContrato.deObjeto = contrato.deObjeto;
            this.dadosContrato.flCompliance = contrato.flComplice;
            this.dadosContrato.flEmailValido = contrato.flEmailValido;
            this.dadosContrato.flLgpd = contrato.flLgpd;
            this.dadosContrato.flContratoTempoIndeterminado =
                contrato.flContratoTempoIndeterminado;
            this.dadosContrato.flNda = contrato.flNda;
            this.dadosContrato.flEmailValido = contrato.flEmailValido;
        },

        alterouTempoIndeterminado() {
            if (!this.dadosContrato.flContratoTempoIndeterminado)
                this.vigenciaFinal = null;
        },
        setarIdContrato(idContrato) {
            this.idCadastro = idContrato;
        },
        AbrirNoMaps() {
            let rua = this.dadosContrato.imovel.logradouro;
            let numero = this.dadosContrato.imovel.numeroEndereco;
            let cidade = this.dadosContrato.imovel.cidade;
            let estado = this.dadosContrato.imovel.estado;
            var endereco = rua + ", " + numero + ", " + cidade + ", " + estado; // Endereço completo concatenado

            var url =
                "https://www.google.com/maps/search/?api=1&query=" +
                encodeURIComponent(endereco);

            window.open(url);
        },

        retornoSucessoPesquisaCliente(pessoa) {
            if (this.tipoPessoaBuscaOuCadastro == 0)
                this.AdicionarContratantes(pessoa);
            else this.AdicionarContratados(pessoa);
            this.fecharModalPesquisaPessoa();
        },
        retornoSucessoCadastroCliente(pessoa) {
            if (this.tipoPessoaBuscaOuCadastro == 0)
                this.AdicionarContratantes(pessoa);
            else this.AdicionarContratados(pessoa);
            this.fecharModal();
        },
        LimparCadastro() {
            this.idCadastro = 0;
            this.arquivos = [];
            this.contratoHistorico = [];
            this.contratantes = [];
            this.contratados = [];
            this.dadosContrato.categoria = 0;
            this.validadoJuridico = false;
            this.validadoComercial = false;
            this.assinatura = false;
            this.renovacaoAutomatico = false;
            this.mensagens = [];
            this.lembretes = [];
            this.vigenciaInicial = null;
            this.vigenciaFinal = null;
            this.dadosContrato.deObjeto = "";
            this.dadosContrato.dtAssinatura = "";
            this.dadosContrato.flContratoTempoIndeterminado = false;
            this.dadosContrato.flLgpd = false;
            this.dadosContrato.flNda = false;
            this.dadosContrato.flCompliance = false;
            this.dadosContrato.flEmailValido = false;
            this.dadosContrato.vlContrato = 0;
            this.dadosContrato.encerrado = false;
            this.dadosContrato.imovel.metroQuadrado = 0;
            this.dadosContrato.imovel.valorTotalImovel = 0;
            this.dadosContrato.imovel.descricaoImovel = "";
            this.dadosContrato.observacoes = "";
            this.dadosContrato.imovel.cep = "";
            this.dadosContrato.imovel.estado = "";
            this.dadosContrato.imovel.cidade = "";
            this.dadosContrato.imovel.bairro = "";
            this.dadosContrato.imovel.logradouro = "";
            this.dadosContrato.imovel.numeroEndereco = "";
            this.dadosContrato.imovel.complemento = "";
        },
        podeExcluirAviso(lembrete) {
            if (!this.permissaoExcluirEncerrar) return false;
            if (
                lembrete.descricaoLembrete == "Contrato à 90 dias do vencimento"
            )
                return false;

            if (lembrete.descricaoLembrete == "Contrato à 1 dia do vencimento")
                return false;

            return true;
        },
        ConsultarCep() {
            if (
                this.dadosContrato.imovel.cep == "" ||
                this.dadosContrato.imovel.cep.length != 9
            )
                return;
            this.isLoading = true;
            axios
                .get(
                    `https://viacep.com.br/ws/${this.dadosContrato.imovel.cep}/json/`
                )
                .then((response) => {
                    let endereco = response.data;
                    this.dadosContrato.imovel.logradouro = endereco.logradouro;
                    this.dadosContrato.imovel.bairro = endereco.bairro;
                    this.dadosContrato.imovel.cidade = endereco.localidade;
                    this.dadosContrato.imovel.estado = endereco.uf;
                    this.dadosContrato.imovel.numeroEndereco = "";

                    this.isLoading = false;
                })
                .catch((error) => {
                    console.error(error);
                    this.isLoading = false;
                });
        },
        fecharModal() {
            this.UiStatus.ModalAberto = false;
            this.UiStatus.ModalPessoa = false;
        },
    },
};
</script>
<style scoped>
.row {
    margin-bottom: 10px;
}
.col-label {
    width: 100%;
    text-align: left;
}
.ml-2 {
    margin-left: 5px;
}
.ta-l {
    text-align: left;
}
.ta-r {
    text-align: right;
}
#div-main {
    background-color: white;
    min-height: 100vh;
}
</style>